/* eslint-disable */

import React, { useEffect, useState } from 'react';
import {
  Card,
  Checkbox,
  Modal,
  Button,
  List,
  Avatar,
  Skeleton,
  Steps,
  Menu,
  Input,
  Select,
} from 'antd';
import { DatePicker, Radio, Switch, Tile } from '@patternfly/react-core';
import { AppContext } from '../AppLayout/AppLayout';
import ReactPlayer from 'react-player';
import { formatToMoney } from '../../data/utils';
import { CartListView, priceLabel } from '../../data';
import useAxios from 'axios-hooks';
import { ICampaign, IPromotionData, IPromotionRecordData, IPublisherOption } from '../../interfaces';
import { keyBy, rearg } from 'lodash';
import { addBusinessDays } from 'date-fns';
import { AddCircleOutline } from '@material-ui/icons';
import { DropzoneDialog } from 'material-ui-dropzone';
import { axios } from '../../index';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckableTag from 'antd/lib/tag/CheckableTag';

const { Step } = Steps; 

const CheckUncheckAll = (props: any) => {
  return (
    <div className='header-select'>
       <label className="ant-list-item-meta-title" style={{float:'left'}}>Price Label {' '} 
        <Select value={props.currentCampaign?.priceLabel} onChange={(v) => props.onChangePrice({ priceLabel: v })}>
            {priceLabel?.map((item:any) => {
                 return <Select.Option key={item?.id} value={item?.id}>{item?.value}</Select.Option>
            })} 
        </Select>
      </label>
      <label className="ant-list-item-meta-title">{props.checkStatus ? 'Unselect All ' : 'Select All '}
        <Checkbox
          id='selectAll'
          onClick={props.onCheckAll}
          checked={props.checkStatus}
        />
      </label>
    </div>
  )
};

const dateFormat = (date: Date) => date.toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
const dateParse = (date: any) => {
  const split = date.split('/');
  if (split.length !== 3) {
    return new Date();
  }
  let month = split[0];
  let day = split[1];
  let year = split[2];
  return new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
};

let today = new Date();
let dd = String(today.getDate()).padStart(2, '0');
let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
let yyyy = today.getFullYear();
const minDate = addBusinessDays(new Date(mm + '/' + dd + '/' + yyyy), 1);    // change from 2 days to 1
//const minDate = addBusinessDays(new Date(), 3); 
const startDateValidator = (date: Date) => {
  if (date < minDate) {
    return 'Date is before the allowable range.';
  }
  return '';
};
const endDateValidator = (startDate: string) => {
  const sd = dateParse(startDate);
  return (date: Date) => {
    if (date < sd) {
      return 'Date is before the start date.';
    }
    return startDateValidator(date);
  };
};
const skipPublisherStep = true;
const pubOptions: IPublisherOption[] = [];
const loadingPubOptions = false;

const CampaignCreator: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { Option } = Select;
  const [uploadingVideo, setUploadingVideo] = React.useState(false);
  const [uploadingCanvas, setUploadingCanvas] = React.useState(false);
  const [uploadingImage, setUploadingImage] = React.useState(false);
  const lastStepMap = React.useRef<Record<number, { currentStep: number; setupStep: number; createStep: number }>>({});
  const {
    salesforceJobList,
    cartDataFinal,
    editCartId,
    dealerInfo,
    checkedPromotions,
    editingCampaigns,
    setEditingCampaigns,
    updateCart,
    getUrl,
    handleCampaignCreatorClose,
    inventoryDataEntries,
    setInventoryDataEntries,
    cartTotal,
    cartTotalList,
    disclaimer,
    editReviseId,
    editReviseOpen,
    seteditReviseOpen,
    setDisplayMessage,
    handleLogout
  } = React.useContext(AppContext);
  const [showVideoUploadModal, setShowVideoUploadModal] = React.useState(false);
  const [showCanvasUploadModal, setShowCanvasUploadModal] = React.useState(false);
  const [showImageUploadModal, setShowImageUploadModal] = React.useState(false);
  const [{ data: inventoryPromotionData, loading: loadingInventoryPromotionData, error:errorInventoryPromotionData }] = useAxios<IPromotionData>(getUrl('getInventoryPromotionData', `&inventoryWrapperIds=${JSON.stringify(checkedPromotions)}&isJobEdit=${editCartId>0 || editReviseId>0?1:0}`));
  const [currentCampaignIndex, setCurrentCampaignIndex] = React.useState(editReviseId? editingCampaigns.findIndex(x => x.inventorySetIds.length!=0)>0?editingCampaigns.findIndex(x => x.inventorySetIds.length!=0):0:0);
  const [currentStep, setCurrentStep] = React.useState(0);
  const [setupStep, setSetupStep] = React.useState(editReviseId?1:0);
  const [createStep, setCreateStep] = React.useState(0);
  const currentCampaignClient = editingCampaigns[currentCampaignIndex];
 
  const checkedInventory = checkedPromotions.map(inventoryWrapperId => inventoryDataEntries.find((d) => d[1].inventoryWrapperId === inventoryWrapperId));
  const numOfWrappers = checkedInventory.length;
  const [currentCampaignName, currentCampaignServer] = checkedInventory[currentCampaignIndex] || [];
  const [{ data: canvases, loading: loadingcanvases, error:errorcanvases }] = useAxios(getUrl('getAssets', `&type=canvas&evergreenVideoId=${JSON.stringify(currentCampaignClient?.evergreenVideoId)}`));
  const [{ data: images, loading: loadingImage, error:errorImage }] = useAxios(getUrl('getAssets', `&type=image`));
  let selectedCamp = cartDataFinal.find((item:any) => item.cartId === editCartId);
  let selectedCampJob = salesforceJobList?.find((item:any) => item.jobId == selectedCamp?.budgetJobId);
  const addImage1 = currentCampaignClient?.imageId1 ? `&leftImage=${JSON.stringify(currentCampaignClient?.imageId1)}` : '&leftImage=940';
  const addImage2 = currentCampaignClient?.imageId2 ? `&rightImage=${JSON.stringify(currentCampaignClient?.imageId2)}` : '&rightImage=940';
  const addCanvas = currentCampaignClient?.canvasId ? `&canvasId=${JSON.stringify(currentCampaignClient?.canvasId)}` : '';
  const url = `${addImage1}${addImage2}${addCanvas}&inventorySetIds=${JSON.stringify(currentCampaignClient?.inventorySetIds)}&evergreenVideoId=${currentCampaignClient?.evergreenVideoId}&budgetJobId=${currentCampaignClient?.budgetJobId}&priceLabel=${currentCampaignClient?.priceLabel}`
  const [{ data: preview, loading: loadingPreview, error:errorPreview }, generatePreview] = useAxios(getUrl('createPreview', url), { manual: true });

  const [budgetJobId, setBudgetJobId] = React.useState(currentCampaignClient?.budgetJobId?currentCampaignClient?.budgetJobId:'');
  const allIds = currentCampaignServer?.inventorySets.map(s => s.inventorySetId);
  const selectedIds = currentCampaignClient?.inventorySetIds;
  const [selectAllFlag, setAllFlag] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(selectedIds == allIds ? true : false);

  let hasOemLogo = currentCampaignClient?.imageId1 ? currentCampaignClient?.imageId1: images?.assets?.image?.find((x:any) => x.defaultType==='oemLogo')?.assetId
  let hasClientLogo =currentCampaignClient?.imageId2? currentCampaignClient?.imageId2: images?.assets?.image?.find((x:any) => x.defaultType==='clientLogo')?.assetId
  React.useEffect(() => {
   if(salesforceJobList?.length){
    setBudgetJobId((editCartId>0)?(selectedCampJob?.jobId):(salesforceJobList[0]?.jobId));
    handleUpdateCurrentCampaign({ budgetJobId:(editCartId>0)?(selectedCampJob?.jobId):(salesforceJobList[0]?.jobId) });

   }else{
    setBudgetJobId('');
   }
  }, [salesforceJobList]);


  React.useEffect(() => {
    if (typeof inventoryPromotionData === 'object') {
      const newData = Object.entries(inventoryPromotionData.inventoryData).map(([title, value], index) => [
        title,
        { ...value, inventoryWrapperId: checkedPromotions[index] }
      ] as [string, IPromotionRecordData]);
      setInventoryDataEntries(prevState => {
        const nextState = [...prevState];

        newData.forEach(newDataRecord => {
          const existingIndex = prevState.findIndex((oldDataRecord) => oldDataRecord[1].inventoryWrapperId === newDataRecord[1].inventoryWrapperId);
          // replace with new data
          if (existingIndex > -1) {
            nextState[existingIndex] = newDataRecord;
          } else {
            nextState.push(newDataRecord);
          }
        });

        return nextState;
      });
    }
  }, [inventoryPromotionData]);

  React.useEffect(() => {
    lastStepMap.current[currentCampaignIndex] = { currentStep, setupStep, createStep };
  }, [currentCampaignIndex, currentStep, setupStep, createStep]);

  const handleUpdateCurrentCampaign = React.useCallback((newVals: Partial<ICampaign>) => {
    setEditingCampaigns(prevState => prevState?.map(
      (c, i) => i === currentCampaignIndex
        ? { ...c, ...newVals }
        : c
    ));
  }, [setEditingCampaigns, currentCampaignIndex]);
  React.useEffect(() => {
    if (!pubOptions?.length) {
      return;
    }
    handleUpdateCurrentCampaign({
      publisherOptions: pubOptions.map(({ channelId }) => ({
        channelId,
        finalUrl: null,
        campaignId: null,
        adGroupId: null,
      })),
    });
  }, [pubOptions, handleUpdateCurrentCampaign]);

  const selectedPackage = currentCampaignServer?.packages.find(p => p.packageId === currentCampaignClient.packageId);
  const pricingMap = keyBy(selectedPackage?.pricing || [], 'inventorySetId');
  const selectedInventorySets = currentCampaignServer?.inventorySets.filter(i => currentCampaignClient.inventorySetIds.includes(i.inventorySetId));
  const mergedInventorySets = selectedInventorySets?.map(s => ({ ...s, cost: pricingMap[s.inventorySetId]?.price }));
  const totals = mergedInventorySets?.reduce((t, v, i) => {
    return {
      cost: t.cost + v.cost,
      audiences: i + 1,
      vehicles: t.vehicles + v.numberToPromote,
    };
  }, { cost: 0, audiences: 0, vehicles: 0 });
  const currentEvergreenVideo = inventoryPromotionData?.evergreenVideos?.find(v => v.evergreenVideoId === currentCampaignClient.evergreenVideoId);
  const currentCanvas = canvases?.assets?.canvas?.find((c: any) => c.assetId === currentCampaignClient.canvasId);
  const currentImage1 = currentCampaignClient?.imageId1 ? images?.assets?.image?.find((c: any) => c.assetId === currentCampaignClient.imageId1) : null;
  const currentImage2 = currentCampaignClient?.imageId2 ? images?.assets?.image?.find((c: any) => c.assetId === currentCampaignClient.imageId2) : null;
  const removeInventorySetId = (inventorySetId: number) => handleUpdateCurrentCampaign({
    inventorySetIds: currentCampaignClient?.inventorySetIds.filter(i => i !== inventorySetId),
  });
  const addInventorySetId = (inventorySetId: number) => handleUpdateCurrentCampaign({
    inventorySetIds: [...currentCampaignClient.inventorySetIds, inventorySetId],
  });

  const dateRangeSelected = !!currentCampaignClient?.startDate && (!!currentCampaignClient?.endDate || currentCampaignClient?.subscribed);
  const packageSelected = currentCampaignClient?.packageId !== null;
  const inventorySetSelected = currentCampaignClient?.inventorySetIds?.length > 0;
  const videoSelected = currentCampaignClient?.evergreenVideoId !== null;
  const stepOne = currentStep === 0;
  const salesforceStep = stepOne && setupStep == 0;
  const dateRangeStep = stepOne && setupStep === 1;
  const packageStep = stepOne && setupStep === 2;
  const publisherStep = !skipPublisherStep && stepOne && setupStep === 3;
  const inventorySetStep = currentStep === 1;
  const videoStep = currentStep === 2;
  const evergreenStep = videoStep && createStep === 0;
  const canvasStep = videoStep && createStep === 1;
  const imageStep = videoStep && createStep === 2;
  const reviewStep = currentStep === 3;
  const publisherDataNotCaptured = currentCampaignClient?.publisherOptions.some((o, i) => {
    const source = pubOptions?.[i];
    if (source?.needsFinalUrl && !o.finalUrl) {
      return true;
    }
    if (source?.needsCampaignSelection && (!o.campaignId || !o.adGroupId)) {
      return true;
    }
    return false;
  });
  const nextDisabled = (publisherStep && publisherDataNotCaptured) || (dateRangeStep && !dateRangeSelected) || (packageStep && !packageSelected) || (inventorySetStep && !inventorySetSelected) || (videoStep && !videoSelected) || (imageStep && (!currentImage1 || !currentImage2) ) || (canvasStep && !currentCanvas) || (salesforceStep && !budgetJobId);


  const handleChange = (joibId:any) => {
    setBudgetJobId(joibId);
    handleUpdateCurrentCampaign({ budgetJobId: joibId });
  };

  const handleCheckAll = () => {
    checkAll ? setCheckAll(false) : setCheckAll(true);
    setAllFlag(true);
  }

  const createCampaign = async () => {
  await axios.post(getUrl('saveCartCampaign', `&cartInfo=[${JSON.stringify(currentCampaignClient)}]`));
  updateCart();
  setDisplayMessage([{type:'success',message:'Order has been added to the cart.'}]);
  setTimeout(()=>{ setDisplayMessage([]) },10000);
  }
  const updateCampaign = async () => {
    await axios.post(getUrl('saveCartCampaign', `&cartInfo=[${JSON.stringify(currentCampaignClient)}]&cartId=${editCartId}`));
    updateCart();
  }
  React.useEffect(() => {
    if (selectAllFlag) {
      const allIds = currentCampaignServer?.inventorySets.map(s => s.inventorySetId);
      currentCampaignClient.inventorySetIds = checkAll ? (allIds ? allIds : []) : []

      handleUpdateCurrentCampaign({
        inventorySetIds: currentCampaignClient.inventorySetIds,
      });
      setAllFlag(false);
    }

  }, [selectAllFlag]);
  React.useEffect(() => {

    const allIds = currentCampaignServer?.inventorySets.map(s => s.inventorySetId);
    const selectedIds = currentCampaignClient?.inventorySetIds;
    (JSON.stringify(selectedIds?.sort()) == JSON.stringify(allIds?.sort())) ? setCheckAll(true) : setCheckAll(false);
  }, [currentCampaignClient?.inventorySetIds]);

  const selectLeftImage = (assetId: any) => {
    if(assetId){
         handleUpdateCurrentCampaign({ imageId1: assetId });
      }
  }

  const selectRightImage = (assetId: any) => {
    if(assetId){
      handleUpdateCurrentCampaign({ imageId2: assetId });
    }
  }

  React.useEffect(() => {
    if(hasOemLogo){
      selectLeftImage(hasOemLogo);
    }
  },[hasOemLogo]);

  
  React.useEffect(() => {
    if(hasClientLogo){
      selectRightImage(hasClientLogo);
    }
  },[hasClientLogo]);


  const uploadVideo = async (payload:any) => {
    let response:any;
    try{
      response = await axios.post('https://video.drivehelix.com/uploadEvergreenVideo.php', payload, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      return response;

    }catch(error:any){
      console.log(error,"try upload error",error.response);
      //return error.response;
      if(typeof error.response==='undefined' || error?.response?.status==500){
        response = await axios.post('https://video.drivehelix.com/uploadEvergreenVideo.php', payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
          return response;
        }else{
          return error.response;
      }

    }

  }
  
  const saveRevised = async () => {
    let dataSet = { 
      jobId:editReviseId,
      subscribed: currentCampaignClient?.subscribed,
      startDate: currentCampaignClient?.startDate,
      endDate:   currentCampaignClient?.endDate,
      evergreenVideoId: currentCampaignClient?.evergreenVideoId,
      canvasId: currentCampaignClient?.canvasId,
      imageId1: currentCampaignClient?.imageId1,
      imageId2: currentCampaignClient?.imageId2,
      inventorySetIds: currentCampaignClient?.inventorySetIds,
      inventoryWrapperId:currentCampaignClient?.inventoryWrapperId,
      priceLabel:currentCampaignClient?.priceLabel,
    };
    //console.log(dataSet);
    seteditReviseOpen(true);
    await axios.post(getUrl('editJob'),`${JSON.stringify(dataSet)}`);
    handleCampaignCreatorClose(true);
    seteditReviseOpen(false);

  } 
 
  useEffect(()=>{
    if (`${errorInventoryPromotionData}`.includes('401') 
        || `${errorcanvases}`.includes('401')
        || `${errorImage}`.includes('401')
        || `${errorPreview}`.includes('401')
      ) { 
        handleLogout('');
    }
  },[errorInventoryPromotionData, errorcanvases, errorImage, errorPreview]);

  
  return (
    
    <>
      <Modal
        className="campaign-creator-root"
        visible={true}
        title="Promote Your Inventory"
        onCancel={() => handleCampaignCreatorClose(true)}
        footer={null}
        width={768}
      >
        {loadingInventoryPromotionData || editReviseOpen
          ? <Card loading={true} style={{ background: 'transparent', border: 0 }} />
          : (
            <div style={{ display: 'flex', height: '100%' }}>
              <Menu
                style={{ width: 150, alignItems: 'stretch' }}
                selectedKeys={[`${currentCampaignIndex}`]}
                onSelect={({ key }) => {
                  setCreateStep(0);
                  setSetupStep(editReviseId?1:0);
                  setCurrentStep(0);
                  setCurrentCampaignIndex(Number(key));
                }}
                mode="vertical"
                theme="dark"
              >
                {checkedInventory.map((item, index) => (
                  <Menu.Item key={`${index}`}>
                    {item?.[0]}
                  </Menu.Item>
                ))}
              </Menu>
              <div>
                <div className="wrapper-header">
                  <Card.Meta
                    avatar={currentCampaignServer?.icon ?
                      <Avatar size={50} icon={<img src={currentCampaignServer.icon} alt={currentCampaignName} />} />
                      : undefined
                    }
                    title={currentCampaignName}
                    description={(
                      <div className="wrapper-header-details">
                        <div>
                          Start Date: <strong>{currentCampaignClient.startDate}</strong> - End Date: <strong>{currentCampaignClient.subscribed ? 'NA' : currentCampaignClient.endDate}</strong><br />
                          Vehicles to Promote: <strong>{totals?.vehicles}</strong><br />
                          Channels: <strong>{selectedPackage?.channels.map(c => c.title).join(', ')}</strong><br />
                          Subscribed: {currentCampaignClient.subscribed ? <strong>&#10003;</strong> : <strong>--</strong>}<br />
                        </div>
                        <div className="wrapper-header-details-totals">
                          <Avatar size={40} icon={selectedPackage?.icon && <img src={selectedPackage.icon} alt={selectedPackage.title} />} />
                          <span>{totals && formatToMoney(totals.cost)}</span>
                        </div>
                      </div>
                    )}
                  />
                  <div />
                </div>
                <Steps
                  className="promote-steps"
                  current={currentStep}
                  progressDot
                >
                  <Step title="Setup" />
                  <Step title="Promote" />
                  <Step title="Create" />
                  <Step title="Review" />
                </Steps>
                <div className="steps-action">
                  {currentStep < 3 && (
                    <Button
                      type="primary"
                      disabled={nextDisabled}
                      onClick={() => {
                        if(salesforceStep){
                          setSetupStep(1);
                        }
                        else if (dateRangeStep) {
                          if(editReviseId)
                          setCurrentStep(prevState => prevState + 1);
                          else
                          setSetupStep(2);
                        } else if (!skipPublisherStep && packageStep) {
                          setSetupStep(3);
                        } else if (evergreenStep) {
                          setCreateStep(1);
                        } else if (canvasStep) {
                          setCreateStep(2);
                        }
                        else {
                          if (imageStep) {
                            generatePreview();
                          }
                          setCurrentStep(prevState => prevState + 1);
                        }
                      }}
                    >
                      Next
                    </Button>
                  )}
                  {reviewStep && (
                    <Button type="primary" onClick={() => {
                      editReviseId>0?saveRevised():editCartId>0?updateCampaign():createCampaign();
                      if (currentCampaignIndex === (numOfWrappers - 1)) {
                        handleCampaignCreatorClose();
                        return;
                      }
                      setCreateStep(0);
                      setSetupStep(0);
                      setCurrentStep(0);
                      setCurrentCampaignIndex(prevState => prevState + 1);
                    }}>
                      {editReviseId>0?'Save':(editCartId>0?'Update Cart':'Add To Cart')}
                    </Button>
                  )}
                  {(currentStep > 0 || packageStep || publisherStep || canvasStep || (dateRangeStep && editReviseId==0)) && (
                    <Button
                      style={{ marginLeft: 8 }}
                      onClick={() => {
                        if (publisherStep) {
                          setSetupStep(2);
                        } else if (packageStep) {
                          setSetupStep(1);
                        } else if (canvasStep) {
                          setCreateStep(0);
                        }
                        else if (imageStep) {
                          setCreateStep(1);
                        }
                        else if(dateRangeStep){
                          setSetupStep(0);
                       }
                        else {
                          setCurrentStep(prevState => prevState - 1)
                        }
                      }}
                    >
                      Previous
                    </Button>
                  )}
                  {currentCampaignIndex !== 0 && salesforceStep && (
                    <Button style={{ marginLeft: 8 }} onClick={() => {
                      const last = lastStepMap.current[currentCampaignIndex - 1];
                      setCurrentCampaignIndex(prevState => prevState - 1);
                      setCreateStep(last.createStep || 0);
                      setSetupStep(last.setupStep || 0);
                      setCurrentStep(last.currentStep || 0);
                    }}>
                      Previous Section
                    </Button>
                  )}
                </div>
                {salesforceStep && (
                  <div className="evergreen-videos">
                    <h3>Select Salesforce Job</h3>
                      <div ref={element => { if (element) element.style.setProperty('float', 'none', 'important'); 
                   }}>
                     <Select className='ant-select'
                                  value={budgetJobId}
                                  onChange={(item: any) => handleChange(item)}
                                >
                                  {salesforceJobList?.map((item:any) => {
                                    if(item){
                                    return <Select.Option key={item?.jobId} value={item?.jobId}>{item?.jobNumber}</Select.Option>
                                    }
                                    })}
                                </Select>
                      </div>                   
                  </div>
                )}
                {dateRangeStep && (
                  <div className="schedule-step">
                    <h3>Select Schedule</h3>
                    <div className="date-picker-cell">
                      <span>Start Date</span>
                      <DatePicker
                        value={currentCampaignClient?.startDate}
                        placeholder="MM/DD/YYYY"
                        dateFormat={dateFormat}
                        dateParse={dateParse}
                        validators={[startDateValidator]}
                        onChange={(v) => handleUpdateCurrentCampaign({ startDate: v })}
                      />
                    </div>
                    <div className="date-picker-cell">
                      <span>End Date</span>
                      <DatePicker
                        value={currentCampaignClient?.endDate}
                        placeholder="MM/DD/YYYY"
                        dateFormat={dateFormat}
                        dateParse={dateParse}
                        isDisabled={currentCampaignClient.subscribed}
                        validators={[endDateValidator(currentCampaignClient.startDate)]}
                        onChange={(v) => handleUpdateCurrentCampaign({ endDate: v })}
                      />
                    </div>
                    <Switch
                      label="Subscribed!"
                      labelOff="Subscribe?"
                      isChecked={currentCampaignClient.subscribed}
                      onChange={(checked) => {handleUpdateCurrentCampaign({ subscribed: checked });handleUpdateCurrentCampaign({ endDate: '' })}}
                    />
                    <p>By subscribing, your campaign will continue to run in this mode until you switch off.</p>
                  </div>
                )}
                {packageStep && (
                  <div className="media-item">
                    <h3>Choose a Media Package</h3>
                    <List
                      className="view-check-list"
                      loading={false}
                      itemLayout="horizontal"
                      dataSource={currentCampaignServer?.packages}
                      renderItem={(p) => (
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          actions={[
                            <div key="check-list-amount">
                              {formatToMoney(p.cost)}<br />
                              <div className="package-channels">
                                {p.channels.map((c, i) => (
                                  <Avatar key={i} icon={<img src={c.icon} alt={c.title} />} />
                                ))}
                              </div>
                              <Radio
                                aria-label={`${p.packageId}`}
                                className="package-radio"
                                name="packageId"
                                id={`${p.packageId}`}
                                isChecked={p.packageId === currentCampaignClient.packageId}
                                value={p.packageId}
                              />
                            </div>
                          ]}
                          onClick={() => {
                            handleUpdateCurrentCampaign({
                              packageId: p.packageId,
                              inventorySetIds: p.pricing.map(pr => pr.inventorySetId),
                            });
                          }}
                        >
                          <Skeleton avatar title={false} loading={false} active>
                            <List.Item.Meta
                              avatar={
                                <Avatar icon={<img src={p.icon} alt={p.title} />} />
                              }
                              title={p.title}
                              description={(
                                <div>
                                  {p.bullets.map(([a, b], i) => (
                                    <span key={i}>{a}:&nbsp;&nbsp;{b}<br /></span>
                                  ))}
                                </div>
                              )}
                            />
                          </Skeleton>
                        </List.Item>
                      )}
                    />
                  </div>
                )}
                {publisherStep && (
                  <div className="media-item">
                    <h3>Choose Publisher Options</h3>
                    <List
                      className="publisher-options-list"
                      loading={loadingPubOptions}
                      itemLayout="horizontal"
                      dataSource={pubOptions || []}
                      renderItem={(p, index) => {
                        const publisherOption = currentCampaignClient.publisherOptions[index];
                        const campaignId = publisherOption?.campaignId || '';
                        const adGroups = p.campaigns?.[campaignId]?.adGroups;
                        return (
                          <div className="publisher-options-list-item" key={p.channelId}>
                            <h6>{p.channelName}</h6>
                            {p.needsFinalUrl && (
                              <div>
                                <p>Enter Click Through URL</p>
                                <Input
                                  value={publisherOption?.finalUrl || ''}
                                  onChange={({ target: { value } }) => handleUpdateCurrentCampaign({
                                    publisherOptions: currentCampaignClient.publisherOptions.map((o, i) => i === index ? ({ ...o, finalUrl: value }) : o)
                                  })}
                                />
                              </div>
                            )}
                            {p.needsCampaignSelection && (
                              <div>
                                <p>Select a Campaign</p>
                                <Select
                                  value={campaignId}
                                  onChange={(value: string) => handleUpdateCurrentCampaign({
                                    publisherOptions: currentCampaignClient.publisherOptions.map((o, i) => i === index ? ({ ...o, campaignId: value }) : o)
                                  })}
                                >
                                  {p.campaigns && Object.entries(p.campaigns).map(([id, c]) => (
                                    <Select.Option key={id} value={id}>{c.name}</Select.Option>
                                  ))}
                                </Select>
                              </div>
                            )}
                            {campaignId && (
                              <div>
                                <p>Select Campaign Ad Group</p>
                                <Select
                                  value={publisherOption?.adGroupId || ''}
                                  onChange={(value: string) => handleUpdateCurrentCampaign({
                                    publisherOptions: currentCampaignClient.publisherOptions.map((o, i) => i === index ? ({ ...o, adGroupId: value }) : o)
                                  })}
                                >
                                  {adGroups && Object.entries(adGroups).map(([id, name]) => (
                                    <Select.Option key={id} value={id}>{name}</Select.Option>
                                  ))}
                                </Select>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    />
                  </div>
                )}
                {inventorySetStep && (
                  <List
                    itemLayout="horizontal"
                    dataSource={currentCampaignServer?.inventorySets}
                    header={<CheckUncheckAll
                      onCheckAll={handleCheckAll}
                      checkStatus={checkAll}
                      currentCampaign={currentCampaignClient}
                      onChangePrice={handleUpdateCurrentCampaign}


                    />}
                    renderItem={item => {
                      const checked = currentCampaignClient.inventorySetIds.includes(item.inventorySetId);
                      return (
                        <List.Item
                          actions={[
                            <Checkbox
                              checked={checked}
                            />
                          ]}
                          style={{ cursor: 'pointer' }}
                          onClick={() => checked ? removeInventorySetId(item.inventorySetId) : addInventorySetId(item.inventorySetId)}
                        >
                          <List.Item.Meta
                            avatar={<Avatar size={75} icon={<img src={item.icon} alt={item.title} />} />}
                            title={item.title}
                            description={(
                              <div className="car-list-extra">
                                {item.bullets.map(([a, b], i) => (
                                  <span key={i}>{a}:&nbsp;&nbsp;<strong>{b}</strong><br /></span>
                                ))}
                              </div>
                            )}
                          />
                        </List.Item>
                      );
                    }}
                  />
                )}
                {evergreenStep && (
                  <div className="evergreen-videos">
                    <h3>Select Evergreen Video</h3>
                    {currentEvergreenVideo && (
                      <div>
                        <ReactPlayer
                          url={currentEvergreenVideo.video}
                          controls={true}
                          light={currentEvergreenVideo.icon}
                          width="100%"
                          height={200}
                        />
                      </div>
                    )}
                    <div>
                      <Tile
                        title="Upload Video"
                        icon={<AddCircleOutline />}
                        isStacked
                        isDisplayLarge
                        className="video-upload-tile"
                        onClick={() => setShowVideoUploadModal(true)}
                      />{' '}
                      {showVideoUploadModal && (
                        <DropzoneDialog 
                          showAlerts={false}
                          dialogTitle="Upload Evergreen Video"
                          open
                          acceptedFiles={['video/*']}
                          dropzoneText="Click or drop a video to host"
                          filesLimit={1}
                          maxFileSize={630000000000}
                          showFileNamesInPreview
                          showPreviews={true}
                          submitButtonText={uploadingVideo ? 'Uploading...' : 'Upload'}
                          onClose={() => setShowVideoUploadModal(false)}
                          onSave={async (files) => {
                            const file = files[0];
                            if (!file) {
                              return;
                            }
                            if(file.size>150000000){
                              enqueueSnackbar(`The video has exceeded the size limit of 150 megabytes. Please re-upload the correct size.`, { variant: 'error' });
                              return;
                            }
                            
                            try {
                              setUploadingVideo(true);
                              const formData = new FormData();
                              formData.append('ucid', `${dealerInfo.ucid}`);
                              formData.append('videoFile',  file);
                              formData.append('includeInGallery', '1');

                              const response = await axios.post('https://video.drivehelix.com/uploadEvergreenVideo.php', formData, {
                                headers: {
                                  'Content-Type': 'multipart/form-data'
                                }
                              }); 
                              // const payload = qs.stringify({
                              //   ucid: `${dealerInfo.ucid}`,
                              //   name: file.name,
                              //   data: await getBase64(file),
                              //   includeInGallery: '1'
                              // });
                              // let response = await uploadVideo(payload);
                              // if(!(response?.data?.success)){
                              //   response = await uploadVideo(payload);
                              // }
                              // if(!(response?.data?.success)){
                              //   response = await uploadVideo(payload);
                              // }
                              // if(!(response?.data?.success)){
                              //   response = await uploadVideo(payload);
                              // }
                              if (response?.data?.success) {
                                inventoryPromotionData?.evergreenVideos.unshift({
                                  evergreenVideoId: response.data.id,
                                  icon: `https://video.drivehelix.com/assets/videos/thumbnails/${response.data.name}.jpg`,
                                  video: `https://video.drivehelix.com/assets/videos/${response.data.name}`,
                                });
                                setShowVideoUploadModal(false);
                                handleUpdateCurrentCampaign({ evergreenVideoId: response.data.id });
                              } else {
                                console.log(response," error upload 1");
                                if(response.status==400){
                                  enqueueSnackbar(response.data.errors.join(", "), { variant: 'error' });
                                 } else {
                                 enqueueSnackbar(`An unknown error has occurred. Please try your request again.`, { variant: 'error' });
                                }
                              }
                            } catch (error:any) {
                              console.log(error,"error upload 2");
                              enqueueSnackbar(`An unknown error has occurred. Please try your request again .`, { variant: 'error' });
                              //enqueueSnackbar(`Couldn't upload evergreen video. Please try again.`, { variant: 'error' });
                            } finally {
                              setUploadingVideo(false);
                            }
                          }}
                        />
                      )}
                      {inventoryPromotionData?.evergreenVideos.map(v => (
                        <React.Fragment key={v.evergreenVideoId}>
                          <Tile
                            title=""
                            icon={<img src={v.icon} alt="" width={150} />}
                            isStacked
                            isDisplayLarge
                            isSelected={v.evergreenVideoId === currentCampaignClient.evergreenVideoId}
                            onClick={() => handleUpdateCurrentCampaign({ evergreenVideoId: v.evergreenVideoId })}
                          />{' '}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}
                {canvasStep && (
                  <div className="evergreen-videos">
                    <h3>Select Canvas</h3>
                    {currentCanvas && (
                      <div>
                        <ReactPlayer
                          url={currentCanvas.url}
                          controls={true}
                          light={currentCanvas.previewUrl}
                          width="100%"
                          height={200}
                        />
                      </div>
                    )}
                    <div>
                      <Tile
                        title="Upload Canvas"
                        icon={<AddCircleOutline />}
                        isStacked
                        isDisplayLarge
                        className="video-upload-tile"
                        onClick={() => setShowCanvasUploadModal(true)}
                      />{' '}
                      {showCanvasUploadModal && (
                        <DropzoneDialog
                          showAlerts={false}
                          dialogTitle="Upload Canvas"
                          open
                          // acceptedFiles={['video/*']}
                          dropzoneText="Click or drop a canvas to host"
                          filesLimit={1}
                          maxFileSize={630000000000}
                          showFileNamesInPreview
                          showPreviews={true}
                          submitButtonText={uploadingCanvas ? 'Uploading...' : 'Upload'}
                          onClose={() => setShowCanvasUploadModal(false)}
                          onSave={async (files) => {
                            const file = files[0];
                            if (!file) {
                              return;
                            }
                            if(file.size>60000000){
                              enqueueSnackbar(`The video has exceeded the size limit of 60 megabytes. Please re-upload the correct size.`, { variant: 'error' });
                              return;
                            }
                            console.log(files,"====");
                            try {
                              setUploadingCanvas(true);
                              const formData = new FormData();
                              formData.append('ucid', `${dealerInfo.ucid}`);
                              formData.append('type', 'canvas');
                              formData.append('name', file.name);
                              formData.append('assetFile',  await file);

                              const response = await axios.post('https://video.drivehelix.com/uploadAsset.php', formData, {
                                headers: {
                                  'Content-Type': 'multipart/form-data'
                                }
                              }); 
                              if (response?.data?.success) {
                                canvases?.assets?.canvas?.unshift({
                                  assetId: response.data.id,
                                  previewUrl: `https://video.drivehelix.com/assets/canvases/${file.name}.gif`,
                                  url: `https://video.drivehelix.com/assets/canvases/${file.name}`,
                                });
                                setShowCanvasUploadModal(false);
                                handleUpdateCurrentCampaign({ canvasId: response.data.id });
                              }   
                               
                            } catch (error:any) {
                              console.log(error,"error upload canvas 2");
                              if(error?.response?.status==400){
                                enqueueSnackbar(error.response.data.errors.join(", "), { variant: 'error' });
                               }else{  
                                enqueueSnackbar(`An unknown error has occurred. Please try your request again.`, { variant: 'error' });
                               }
                              //enqueueSnackbar(`Couldn't upload canvas. Please try again.`, { variant: 'error' });
                            } finally {
                              setUploadingCanvas(false);
                            }
                          }}
                        />
                      )}
                      {canvases?.assets?.canvas?.map((c: any) => (
                        <React.Fragment key={c.assetId}>
                          <Tile
                            title=""
                            icon={<img src={c.previewUrl} alt="" width={150} />}
                            isStacked
                            isDisplayLarge
                            isSelected={c.assetId === currentCampaignClient.canvasId}
                            onClick={() => handleUpdateCurrentCampaign({ canvasId: c.assetId })}
                          />{' '}
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}

                {imageStep && (
                  <div className="evergreen-videos">
                    <h3>Select Images</h3>
                    <div className='selectedImagesBlock'>
                      <table className='selectedImagesTable'>
                        <tr>
                          <td className='leftImage' style={{backgroundColor:'white',height:!currentImage1?'100px':'auto',width:'50%',borderRight:'solid',borderRightColor:'black'}}>
                            
                            {currentImage1?<img src={currentImage1.url} height='100px' width='100%' title='Left Image'></img>:<span className='noImageText'>Select Left Image</span>}

                          </td>
                          <td style={{backgroundColor:'white',height:!currentImage1?'100px':'auto'}}>
                            {currentImage2?<img src={currentImage2.url} height='100px' width='100%' title='Right Image'></img>:<span className='noImageText'>Select Right Image</span>}
                          </td>
                        </tr>
                      </table>


                    </div>
                    <div>
                      <Tile
                        title="Upload Image"
                        icon={<AddCircleOutline />}
                        isStacked
                        isDisplayLarge
                        className="video-upload-tile"
                        onClick={() => setShowImageUploadModal(true)}
                      />{' '}
                      {showImageUploadModal && (
                        <DropzoneDialog
                          showAlerts={false}
                          dialogTitle="Upload Image"
                          open
                          dropzoneText="Click or drop a image to host"
                          filesLimit={1}
                          maxFileSize={630000000000}
                          showFileNamesInPreview
                          showPreviews={true}
                          submitButtonText={uploadingImage ? 'Uploading...' : 'Upload'}
                          onClose={() => setShowImageUploadModal(false)}
                          onSave={async (files) => {
                            const file = files[0];
                            if (!file) {
                              return;
                            }
                            if(file.size>60000000){
                              enqueueSnackbar(`The image has exceeded the size limit of 60 megabytes. Please re-upload the correct size.`, { variant: 'error' });
                              return;
                            }
                            
                            try {
                              setUploadingImage(true);
                              const formData = new FormData();
                              formData.append('ucid', `${dealerInfo.ucid}`);
                              formData.append('type', 'image');
                              formData.append('name', file.name);
                              formData.append('assetFile', await file);
                              const response = await axios.post('https://video.drivehelix.com/uploadAsset.php', formData, {
                                headers: {
                                  'Content-Type': 'multipart/form-data'
                                }
                              });
                              if (response?.data?.success) {
                                images?.assets?.image?.unshift({
                                  assetId: response.data.id,
                                  previewUrl: `https://video.drivehelix.com/assets/${dealerInfo.ucid}/images/${response.data.filename}`,
                                  url: `https://video.drivehelix.com/assets/${dealerInfo.ucid}/images/${response.data.filename}`,
                                });
                                setShowImageUploadModal(false);
                                selectLeftImage(response.data.id);
                              } else {
                                console.log(response," error upload image 1");
                                if(response.status==400){
                                  enqueueSnackbar(response.data.errors.join(", "), { variant: 'error' });
                                 } else {
                                 enqueueSnackbar(`An unknown error has occurred. Please try your request again.`, { variant: 'error' });
                                }
                              } 
                               
                            } catch (error:any) {
                              console.log(error,"error upload image 2");
                              if(error?.response?.status==400){
                                enqueueSnackbar(error.response.data.errors.join(", "), { variant: 'error' });
                               }else{  
                                enqueueSnackbar(`An unknown error has occurred. Please try your request again.`, { variant: 'error' });
                               }
                               //enqueueSnackbar(`Couldn't upload image. Please try again.`, { variant: 'error' });
                            } finally {
                              setUploadingImage(false);
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className='selectionBlock'>
                      <table className='selectionTable'>
                        <tr>
                          <td className='leftImageLabel' >Select Left Image</td>
                          <td className='imageSelection'>
                          <Select defaultValue={currentImage1?currentImage1.assetId:(hasOemLogo?hasOemLogo:null)} className='antSelectLogo' placeholder="Select Left Image" onChange={selectLeftImage}>
                             {images?.assets?.image?.map((c: any, index: number) => (
                               <Option key={index} className='selectOption' value={c.assetId}><img src={c.url} title={c.url} /><br/><span style={{color:'black'}}>{c.name}</span></Option>
                             ))}
                           </Select>
                          </td>
                        </tr>
                        <tr style={{ height: '10px' }}></tr>
                        <tr>
                          <td className='leftImageLabel' >Select Right Image</td>
                          <td className='imageSelection'>
                          <Select defaultValue={currentImage2?currentImage2.assetId:(hasClientLogo?hasClientLogo:null)} className='antSelectLogo' placeholder="Select Right Image" onChange={selectRightImage}>
                             {images?.assets?.image?.map((c: any, index: number) => (
                               <Option key={index} className='selectOption' value={c.assetId}><img src={c.url} title={c.url} /><br/><span style={{color:'black'}}>{c.name}</span></Option>
                             ))}
                           </Select>
                          </td>
                        </tr>
                      </table>

                    </div>
                  </div>
                )}
                 

                {false && (
                  <CartListView
                    className="campaign-review-cart"
                    data={cartTotalList}
                    total={cartTotal}
                    disclaimer={disclaimer}
                    showSignature={false}
                  />
                )}
                {reviewStep && (
                  <div className="preview-video">
                    <h3>Preview</h3>
                    {loadingPreview ? <CircularProgress color="secondary" size={50} /> : (
                      <div>
                        {preview && (
                          <ReactPlayer
                            url={preview.url}
                            controls={true}
                            light={preview.previewUrl}
                            width="100%"
                            height={200}
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        }
      </Modal>
      {(uploadingVideo || uploadingCanvas || uploadingImage) && (
        <div className="uploading-video-progress">
          <CircularProgress color="secondary" size={100} />
        </div>
      )}
    </>
  );
}
export default CampaignCreator;

function getBase64(file: File): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
}
