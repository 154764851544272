import React from 'react';
import {Avatar, Card, Col, Icon, Modal, Progress, Row, List, Checkbox} from 'antd';
import {ReactComponent as YouTubeIcon} from '../assets/youtube.svg';
import {ReactComponent as FacebookIcon} from '../assets/facebook.svg';
import {ReactComponent as CtvIcon} from '../assets/ctv.svg';
import {ReactComponent as InappIcon} from '../assets/inapp.svg';
import {ReactComponent as WebsitesIcon} from '../assets/websites.svg';
import {ReactComponent as ActivateIcon} from '../assets/group-38.svg';
import {ISocialTotals, TPublisherOverview, ITopAd, IInventoryItem, ICartTotal} from '../interfaces';
import {formatToCommas, formatToMoney} from '../data/utils';
import {ReactComponent as CruiseIcon} from '../assets/cruise.svg';
import {ReactComponent as AccelIcon} from '../assets/accel.svg';
import {ReactComponent as TurboIcon} from '../assets/turbo.svg';
import {ReactComponent as ManualIcon} from '../assets/manual.svg';
import PerformanceChart from '../components/PerformanceChart/PerformanceChartNew';
import SignatureCanvas from 'react-signature-canvas';
import ReactPlayer from 'react-player';

export const packageIconMap = [
  {
    number: 1,
    icon: <CruiseIcon />,
  },
  {
    number: 2,
    icon: <AccelIcon />,
  },
  {
    number: 3,
    icon: <TurboIcon />,
  },
  {
    number: 5,
    icon: <Icon type="rise" />,
  },
  {
    number: 4,
    icon: <ManualIcon />,
  },
];

export const priceLabel = [
  {
    id: "",
    value: "NA",
  },
  {
    id: "MSRP",
    value: "MSRP",
  },
  {
    id: "Starting MSRP",
    value: "Starting MSRP",
  },
  {
    id: "Starting at",
    value: "Starting at",
  },
  {
    id: "Sale Price",
    value: "Sale Price",
  },
];

export const goalKeyMap = {
  brand: 'key',
  new: 'modelId',
  used: 'segment',
  service: 'key',
};

export const goalTitleMap = {
  brand: 'title',
  new: 'model',
  used: 'segment',
  service: 'title',
};

export const goalModelsMap = {
  brand: [{key: 'awareness', title: 'Awareness'}, {key: 'in-market', title: 'In-Market'}],
  service: [
    {key: 'customer', title: 'Customer Pay'},
    {key: 'warranty', title: 'Warranty Pay'},
    {key: 'conquest', title: 'Conquest'},
  ],
};

export const goalTextMap = {
  brand: 'Brand',
  new: 'New',
  used: 'Used',
  service: 'Service',
};

export const audienceChartData = (data: any) => [
  {
    color: '#299FD8',
    title: data.warm ? 'Warm' : '',
    value: data.warm ? (data.warm / data.total) : 0,
    subtitle: `${data.warm || ''}`,
    legendLabel: '46-90 Days',
  },
  {
    color: '#05E3EF',
    title: data.new ? 'New' : '',
    value: data.new ? (data.new / data.total) : 0,
    subtitle: `${data.new || ''}`,
    legendLabel: '0-45 Days',
  },
  {
    color: '#04C9EF',
    title: data.cold ? 'Cold' : '',
    value: data.cold ? (data.cold / data.total) : 0,
    subtitle: `${data.cold || ''}`,
    legendLabel: '91+ Days',
  },
];

export const serviceChartData = (data: any) => [
  {
    color: '#df9523',
    title: data.customer ? 'Customer Pay' : '',
    value: data.customer ? (data.customer / data.total) : 0,
    subtitle: `${data.customer || ''}`,
    legendLabel: 'Customer Pay',
  },
  {
    color: '#d07312',
    title: data.warranty ? 'Warranty Pay' : '',
    value: data.warranty ? (data.warranty / data.total) : 0,
    subtitle: `${data.warranty || ''}`,
    legendLabel: 'Warranty Pay',
  },
];

export const inventoryView = (data: IInventoryItem[], checkedItems: number[], onCheckItem: (cannedFilterId: number) => void) => (
  <div className="card-scroll-content">
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={item => (
        <List.Item
          actions={[
            <Checkbox
              checked={checkedItems.includes(item.inventoryWrapperId)}
            />
          ]}
          onClick={() => onCheckItem(item.inventoryWrapperId)}
        >
          <List.Item.Meta
            avatar={<Avatar size={75} icon={<img src={item.imageURL} alt={item.inventoryWrapperName} />} />}
            title={item.inventoryWrapperName}
            description={(
              <div className="car-list-extra">
                {item.bullets.map(([a, b], i) => (
                  <span key={i}>{a}:&nbsp;&nbsp;<strong>{b}</strong><br/></span>
                ))}
              </div>
            )}
          />
        </List.Item>
      )}
    />
  </div>
);

export const viewsChartData = (d: ISocialTotals) => [
  {
    color: '#299FD8',
    title: d.used ? 'Used' : '',
    value: d.used ? (d.used / d.total) : 0,
    subtitle: d.used ? formatToCommas(d.used) : '',
  },
  {
    color: '#05E3EF',
    title: d.brand ? 'Brand' : '',
    value: d.brand ? (d.brand / d.total) : 0,
    subtitle: d.brand ? formatToCommas(d.brand) : '',
  },
  {
    color: '#04C9EF',
    title: d.new ? 'New' : '',
    value: d.new ? (d.new / d.total) : 0,
    subtitle: d.new ? formatToCommas(d.new) : '',
  },
];

export const socialMediaList = (d: ISocialTotals) => [
  {
    icon: <YouTubeIcon />,
    name: 'YouTube',
    budget: '$ 500',//formatToMoney(d.youtube.budget),
    impressions: formatToCommas(d.youtube.impressions),
    views: '4,167',//formatToCommas(d.youtube.views),
  },
  {
    icon: <FacebookIcon />,
    name: 'Facebook',
    budget: '$ 500',//formatToMoney(d.facebook.budget),
    impressions: formatToCommas(d.facebook.impressions),
    views: '2,500',//formatToCommas(d.facebook.views),
  },
  /*{
    icon: <InstaIcon />,
    name: 'Instagram',
    budget: formatToMoney(d.instagram.budget),
    impressions: formatToCommas(d.instagram.impressions),
    views: formatToCommas(d.instagram.views),
  },*/
  {
    icon: <CtvIcon />,
    name: 'CTV',
    budget: '$ 1,500',//formatToMoney(d.ctv.budget),
    impressions: formatToCommas(d.ctv.impressions),
    views: '22,059',//formatToCommas(d.ctv.views),
  },
  {
    icon: <InappIcon />,
    name: 'In-App',
    budget: '$ 250',//formatToMoney(d.inapp.budget),
    impressions: formatToCommas(d.inapp.impressions),
    views: '13,899',//formatToCommas(d.inapp.views),
  },
  {
    icon: <WebsitesIcon />,
    name: 'Websites',
    budget: '$ 250',//formatToMoney(d.websites.budget),
    impressions: formatToCommas(d.websites.impressions),
    views: '11,364',//formatToCommas(d.websites.views),
  },
];

export const audiencePacingTabData = (data: TPublisherOverview) => Object.entries(data).map(
  ([title, currentData]) => ({
    title,
    content: (
      <Row className="social-media-list" key={title}>
        {/* <Col xs={24} sm={24} md={24} lg={12} xl={24} xxl={24}>
          <div className="pad-top-2x">
            <PieChartCard
              list={[{
                data: viewsChartData(currentData),
                centerData: {
                  numerator: formatToCommas(currentData.total),
                  // under: '3,600',
                  label: 'Views',
                },
                className: 'views-chart',
                startAngle: 70,
              }]}
              bordered={false}
            />
          </div>
        </Col> */}
        <Col xs={24} sm={24} md={24} lg={12} xl={24} xxl={24}>
          {Array.isArray(currentData) && currentData.map((item, index) => (
            <div className="space-between-row" key={index}>
              <Card.Meta
                avatar={
                  <Avatar
                    className="transparent-back"
                    icon={<img src={item.icon} alt={item.name} />}
                  />
                }
                title={item.name}
                description={(
                  <>
                    {item.descriptionBullets?.map(([l, r], bi) => (
                      <div key={bi}><span>{l}:</span> <span>{r}</span></div>
                    ))}
                  </>
                )}
              />
              <div className="social-data">
                {item.bullets?.map(([l, r], bi) => (
                  <div key={bi}><span>{l}:</span><strong>{r}</strong></div>
                ))}
              </div>
            </div>
          ))}
        </Col>
      </Row>
    ),
  })
);

export const dynamicVideoData = ['New Corolla', 'Used Camry', 'Used Corolla', 'New Tacoma'].map(title => ({
  title,
  purchased: '10,000 Views',
  salesVelocity: 'Increase 25%',
  campaign: 'New Campaign',
  dateRange: '01/01/2020 - 02/01/2020'
}));

export const DynamicVideoView = ({ data }: { data: ITopAd[] }) => {
  const [selectedAd, setSelectedAd] = React.useState<ITopAd | null>(null);
  return (
    <>
      {(Array.isArray(data) ? data : []).map((d, i) => (
        <Row className="dynamic-video-row" key={i} onClick={() => setSelectedAd(d)}>
          <Col span={10}>
            <img src={d.thumbnailURL} alt={`${d.campaignNumber} dynamic video`} />
          </Col>
          <Col span={14}>
            <span className="dynamic-video-title">{d.title}</span>
            <div className="video-data-text">
              {d.bullets.map(([a, b], i) => (
                <span key={i}>{a}:&nbsp;&nbsp;<strong>{b}</strong><br/></span>
              ))}
            </div>
          </Col>
        </Row>
      ))}
      {selectedAd && (
        <Modal
          title={selectedAd.title}
          visible={true}
          onCancel={() => setSelectedAd(null)}
          footer={<></>}
          className="success-modal-root top-ad-player"
        >
          <ReactPlayer
            url={selectedAd.finalVideoName}
            controls={true}
            light={selectedAd.thumbnailURL}
            width="100%"
            height={400}
          />
        </Modal>
      )}
    </>
  );
};

export const activityData = [
  {
    name: 'Ad Level',
    impressions: [2500, 1800],
    clicks: [3000, 2700],
    views: [1700, 1100],
    ctr: [276, 197],
    vtr: [275, 104],
    complete: 100,
  },
  {
    name: 'After Ad Engagement',
    impressions: [2500, 1800],
    clicks: [3000, 2700],
    views: [1700, 1100],
    ctr: [276, 197],
    vtr: [275, 104],
    complete: 100,
  },
  {
    name: 'Dealership',
    impressions: [2500, 1800],
    clicks: [3000, 2700],
    views: [1700, 1100],
    ctr: [276, 197],
    vtr: [275, 104],
    complete: 100,
  },
];

export const activityTabData = ['Day', 'Week', 'Month', 'Year'].map(title => {
  const filteredData = activityData;
  return {
    title,
    content: (
      <div className="activity-scroll-box">
        {filteredData.map((d, i) => (
          <Row className="activity-row" key={i}>
            <Col span={8}>
              <Progress
                type="circle"
                percent={100}
                width={60}
                strokeColor="#B4E846"
                strokeWidth={7}
                format={(per) => `${per}%`}
              />
            </Col>
            <Col span={16}>
              <h4>{d.name}</h4>
              <div className="activity-data-text">
                Impressions: <Icon type="arrow-up" className="arrow-up" />{d.impressions[0]} <ActivateIcon className="small-activate-icon" />{d.impressions[1]}<br/>
                Clicks: <Icon type="arrow-up" className="arrow-up" />{d.clicks[0]} <ActivateIcon className="small-activate-icon" />{d.clicks[1]}<br/>
                Views: <Icon type="arrow-up" className="arrow-up" />{d.views[0]} <ActivateIcon className="small-activate-icon" />{d.views[1]}<br/>
                CTR: <Icon type="arrow-up" className="arrow-up" />{d.ctr[0]} <ActivateIcon className="small-activate-icon" />{d.ctr[1]}<br/>
                VTR: <Icon type="arrow-up" className="arrow-up" />{d.vtr[0]} <ActivateIcon className="small-activate-icon" />{d.vtr[1]}<br/>
              </div>
            </Col>
          </Row>
        ))}
      </div>
    ),
  };
});

export const optimizationsCardData = (data: Record<string, string | number>[]) => {
  const subFilters = data.length ? Object.keys(data[0]).filter(k => k !== 'date') : [];
  return subFilters.map((filterKey) => ({
    title: filterKey,
    content: <PerformanceChart data={data} filterKey={filterKey} />,
  }));
};

/* schedule chart sample */
// {
//   title: 'Schedule',
//   content: (cd && cd.length > 0) ? (
//     <ScheduleChart
//       data={cd.map((c: any) => ({
//         startTime: c.startDate,
//         endTime: c.endDate,
//         title: c.title,
//         package: c.mediaPackageName,
//       }))}
//     />
//   ) : <Empty />,
// },

export function CartListView({
  className,
  data,
  total,
  children,
  signature,
  onSign,
  showSignature = true,
  disclaimer,
  onRemoveCartItem,
  onEditCartItem,
}: {
  className?: string;
  data: ICartTotal[];
  total: number;
  children?: React.ReactNode;
  signature?: string;
  onSign?: (signatureDataURL: string) => void;
  showSignature?: boolean;
  disclaimer?: string;
  onRemoveCartItem?: (uuid: string) => void;
  onEditCartItem?: (uuid: string) => void;  
}) {
  const signatureRef = React.useRef<any>();
  const noData = data.length === 0;
  React.useEffect(() => {
    if (signatureRef.current) {
      if (signature) {
        signatureRef.current.fromDataURL(signature, {width: 320, height: 100});
      } else {
        signatureRef.current.clear();
      }
    }
  }, [signature]);
  return (
    //style={{height:'460px'}}
    <div>
      <div className="space-between-row blue-back" style={{marginBottom: noData ? 100 : 0}}>
        <span>Summary</span>
      </div>
      {data.map((d, i) => (
        <Card.Meta
          key={i}
          className="gray-back cart-row"
          avatar={
            <Avatar size={40} icon={<img src={d.icon} alt={d.title} />} />
          }
          title={(
            <>
              <div>
                <span>{d.title}</span>
                <span><strong>{formatToMoney(d.cost)}</strong></span>
              </div>
              {onEditCartItem && (
                <Icon style={{position:'absolute',right:'40px',top:'46px'}} type="edit" onClick={() => onEditCartItem(d.cartId.toString())} />
              )}
              {onRemoveCartItem && (
                <Icon type="delete" onClick={() => onRemoveCartItem(d.cartId.toString())} />
              )}
            </>
          )}
          description={(
            <div>
              Audiences: <strong>{d.audiences}</strong><br />
              Vehicles: <strong>{d.vehicles}</strong><br />
              Subscribed: <strong>{d.subscribed ? <strong>&#10003;</strong> : <strong>--</strong>}</strong><br />
            </div>
          )}
        />
      ))}
      <div className="space-between-row blue-back" style={{marginBottom: 15}}>
        <span>Total:</span>
        <span><strong>{formatToMoney(total)}</strong></span>
      </div>
      {showSignature && (
        <>
          <p>Sign Below:</p>
          <SignatureCanvas
            ref={signatureRef}
            penColor="#00a6e6"
            canvasProps={{width: 320, height: 100,style:{border:'solid',borderWidth:'2px',borderColor:'White',borderRadius:'10px'}}}
            onEnd={() => onSign?.(signatureRef.current.toDataURL())}
          />
           {disclaimer && <p dangerouslySetInnerHTML={{ __html: disclaimer }} className="disclaimer" />}
         </>
      )}
      {children}
    </div>
  );
}
