export const API_HOST = window.location.hostname === 'my.drive.video' ? 'gcc.drivehelix.com' : 'gccdev.drivehelix.com';
export const API_BASE_PATH = '/ajax/';

const URLS = {
  // global
  GET_DEALER_INFO: `${API_BASE_PATH}?f=getDealershipInfo`,
  // dashboard
  GET_ALL_LEADS: `${API_BASE_PATH}?d=drive&f=getAllLeads`,
  GET_INVENTORY: `${API_BASE_PATH}?d=drive&f=inventory&action=inventoryOperationsActivate`,
  GET_AVAILABLE_CHANNELS: `${API_BASE_PATH}?d=drive&f=getAvailableChannels`,
  GET_VIDEOS: `${API_BASE_PATH}?d=drive&f=getVideoGallery&type=`,
  GET_TEMPLATES: `${API_BASE_PATH}?d=drive&f=getAvailableCanvases&videoId=`,
  CREATE_CAMPAIGNS: `${API_BASE_PATH}?d=drive&f=createDriveCampaign`,
  GET_LIVE_MONITOR: `${API_BASE_PATH}?d=drive&f=getLiveMonitoring`,
  GET_TOP_ADS: `${API_BASE_PATH}?d=drive&f=getTopPerformingAds`,
  GET_CAMPAIGNS: `${API_BASE_PATH}?d=drive&f=getCampaignInfo&dateStart=2019-12-01&dateEnd=2020-04-01`,
};

export default URLS;
