import React, { useEffect } from 'react';
import {AppContext} from '../AppLayout/AppLayout';
import useAxios from 'axios-hooks';
import {DataGrid,GridColDef,GridToolbar} from '@mui/x-data-grid';
import { Tooltip } from '@material-ui/core';
import { Button } from 'antd';
import { Box } from '@mui/material';
import '../../styles/components/Orders.scss';
import { Link } from 'react-router-dom';
import CloneCreator from '../CloneCreator/CloneCreator';


export function Orders() {
  const {ucid,
    orders,
    handleJobSelect,
    setOrderHistoryLabel,
    handleCampaignReviseOpen,
    handleOrderHistoryClose
  } = React.useContext(AppContext);
  const {getUrl} = React.useContext(AppContext);
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [cloneOn,setCloneOn] = React.useState(false);
  const [reviseOn,setReviseOn] = React.useState(false);
  const [order,setOrder] = React.useState({});
  if(orders?.data){
    orders.data.map((item:any) =>{
        item['jobNumberClone'] = item.jobNumber;
        item['jobNumberRevise'] = item.jobNumber;
    })
  } 
  const handleReviseClick = (event:any,value:any) => {
      setCloneOn(false);
      setReviseOn(true); 
      handleOrderHistoryClose()
      handleCampaignReviseOpen(value)
  }
  const handleCloneClick = (event:any,value:any) => {
    setOrder(value?.row)
    setCloneOn(true);
    setReviseOn(false);
    setOrderHistoryLabel('Select Salesforce Job and Duration');
  }
  const handleCloneBack = () => {
    setCloneOn(false);
    setReviseOn(false);
  }

  const columns: GridColDef[] = [
    { field: 'jobNumber', 
    headerName: 'Drive Job',
    width:160,
  },
    { field: 'proposalId', 
    headerName: 'Order Number',
    width:120,
    renderCell:(cellValue)=>{return (<span style={{color: '#1890ff', cursor:'pointer'}} onClick={()=>handleJobSelect(cellValue)} >{cellValue.value}</span>)}
  },
  
    {
      field: 'jobNumberClone',
      headerName: 'Clone',
      renderCell: (cellValues) => {
        return (
          <Button
            className='gridButtons'
            type="primary"
            onClick={(event) => {
              handleCloneClick(event, cellValues);
            }}
          >
            Clone
          </Button>
        );
      },
      width: 100,
      
    },
    {
      field: 'jobNumberRevise',
      headerName: 'Revise',
      renderCell: (cellValues) => {
        return (
          <Button
            className='gridButtons'
            type='primary'
            onClick={(event) => {
              handleReviseClick(event, cellValues);
            }}
          >
            Revise
          </Button>
        );
      },
      width: 100,
    },
    {
      field:'packageName',
      headerName:'Package',
      width:130
    },
      {
      field: 'userName',
      headerName: 'Created By',
      width:120,
      renderCell: (cellValues) => {
        return (
          <>
          <Tooltip title={cellValues.row.userEmail}>
          <a href={'mailto:'+cellValues.row.userEmail} >{cellValues.row.userName}</a>
            </Tooltip>
            </>
        );
      }
    },
    {
      field: 'creationDateTime',
      headerName: 'Order Date',
      width:150,
      renderCell:(cellValue:any)=>{
        return(<><Tooltip title={new Date(cellValue.value).toLocaleDateString('en-US')}>
          <span>{new Date(cellValue.value).toLocaleDateString('en-US')}</span>
        </Tooltip>
      </>)
      },
    }
    
  ];

  return (
    <>{
      (!cloneOn && !reviseOn)?(orders?.data?
      <Box style={{marginRight:'10%',color:'black',width:'inherit',height:'90%'}}>
        {/* {setOrderHistoryLabel('Order History')} */}
     <DataGrid
        rows={(orders)?(orders?.data?.filter((item:any)=>item.ucid == ucid).reverse()):[]}
        getRowId={(row) => row.proposalId}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={columns}
        components={{ Toolbar: GridToolbar }}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 40, 50]}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
      />
      </Box>:<></>):
      (cloneOn && !reviseOn?<>
      <CloneCreator order={order} handleCloneBack={handleCloneBack}/>
      </>:<></>)
      }
    </>
  );
}
