import React from 'react';
import {
  // Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Icon,
  makeStyles,
  TablePagination,
  createStyles,
  Theme,
  useTheme,
  Toolbar,
  Tooltip,
  fade,
  InputBase,
  Dialog,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  Button,
  withStyles,
  WithStyles,
  DialogTitle as MuiDialogTitle,
  TextField,
} from '@material-ui/core';
import {Autocomplete, createFilterOptions} from '@material-ui/lab';
import useAxios from 'axios-hooks';
import {AppContext} from '../AppLayout/AppLayout';
import {IServiceOffer} from '../../interfaces';
import { lowerCase } from 'lodash';
import {DropzoneArea} from 'material-ui-dropzone';
import {axios} from '../../index';
import {useSnackbar} from 'notistack';

const usePaginationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }),
);

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onChangePage: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const classes = usePaginationStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <Icon>last_page</Icon> : <Icon>first_page</Icon>}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === 'rtl' ? <Icon>keyboard_arrow_right</Icon> : <Icon>keyboard_arrow_left</Icon>}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <Icon>keyboard_arrow_left</Icon> : <Icon>keyboard_arrow_right</Icon>}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <Icon>first_page</Icon> : <Icon>last_page</Icon>}
      </IconButton>
    </div>
  );
}

// function Preview(props: { row: IServiceOffer }) {
//   const { row } = props;

//   return (
//     <Box
//       display="inline-block"
//       padding="20px 0"
//       position="relative"
//       textAlign="center"
//       width={300}
//     >
//       <Typography>
//         {row.headline}
//       </Typography>
//       <Typography style={{padding: '5px 0'}}>
//         {row.name}
//       </Typography>
//       <img src={row.imageUrl} alt={row.name} />
//       <Typography style={{padding: '5px 0'}}>
//         {row.amount}
//       </Typography>
//       <Typography style={{fontSize: 10}}>
//         {row.disclaimer}
//       </Typography>
//     </Box>
//   );
// }

const useRowStyles = makeStyles({
  root: {
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props: { row: IServiceOffer, onClick: (row: IServiceOffer) => void; }) {
  const { row, onClick } = props;
  // const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow
        className={classes.root}
        hover
        onClick={row.notEditable ? undefined : () => onClick(row)}
      >
        {/* <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <Icon>keyboard_arrow_up</Icon> : <Icon>keyboard_arrow_down</Icon>}
          </IconButton>
        </TableCell> */}
        <TableCell>{row.offerId}</TableCell>
        <TableCell>{row.name}</TableCell>
        <TableCell>{row.headline}</TableCell>
        <TableCell>{row.amount}</TableCell>
        <TableCell>{row.disclaimer}</TableCell>
        <TableCell>{row.imageUrl}</TableCell>
      </TableRow>
      {/* <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} textAlign="center">
              <Typography variant="h6" gutterBottom component="div">
                Preview
              </Typography>
              <Preview row={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}
    </React.Fragment>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.secondary.dark,
    },
    title: {
      flex: '1 1 100%',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
  }),
);

interface EnhancedTableToolbarProps {
  onAdd: () => void;
  onSearch: (value: string) => void;
}

const EnhancedTableToolbar = ({
  onAdd,
  onSearch,
}: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Services
      </Typography>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <Icon>search</Icon>
        </div>
        <InputBase
          placeholder="Search…"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ 'aria-label': 'search' }}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <Tooltip title="Create service offer">
        <IconButton aria-label="create service offer" onClick={onAdd}>
          <Icon>add</Icon>
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

const useTableStyles = makeStyles({
  root: {
    margin: '30px 30px 100px',
  },
  container: {
    maxHeight: 'calc(100vh - 320px)',
  },
});

export const Service: React.FC = () => {
  const classes = useTableStyles();
  const [searchValue, setSearchValue] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedRow, setSelectedRow] = React.useState<IServiceOffer | null>(null);
  const {getUrl, serviceWrappersData, refetchServiceOffers} = React.useContext(AppContext);
  const [{ data: serviceOfferImageUrls }] = useAxios(getUrl('getServiceOfferImages'));

  const rows = serviceWrappersData?.filter(((i) => lowerCase(i.name).includes(lowerCase(searchValue)))) || [];
  const rowCount = rows.length;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rowCount - page * rowsPerPage);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Paper elevation={5} className={classes.root}>
        <EnhancedTableToolbar
          onAdd={() => setSelectedRow({
            amount: '',
            disclaimer: '',
            headline: '',
            imageUrl: '',
            isGlobal: 0,
            name: '',
          })}
          onSearch={setSearchValue}
        />
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="service table">
            <TableHead>
              <TableRow>
                {/* <TableCell /> */}
                <TableCell>Offer ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Headline</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Disclaimer</TableCell>
                <TableCell>Image URL</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : rows
              ).map((row) => (
                <Row key={row.offerId} row={row} onClick={setSelectedRow}/>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          colSpan={6}
          count={rowCount}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
      {selectedRow && (
        <ServiceModal
          data={selectedRow}
          getUrl={getUrl}
          serviceOfferImageUrls={serviceOfferImageUrls || []}
          refetchServiceOffers={refetchServiceOffers}
          onClose={() => setSelectedRow(null)}
        />
      )}
    </>
  );
}

const dialogTitleStyles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

export interface DialogTitleProps extends WithStyles<typeof dialogTitleStyles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
  style?: React.CSSProperties;
}

const DialogTitle = withStyles(dialogTitleStyles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" style={{color: '#fff'}}>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const imageUrlFilter = createFilterOptions<string>();

interface IServiceModal {
  data: IServiceOffer;
  getUrl: (action: string, more?: string | undefined, dates?: boolean | undefined) => string;
  serviceOfferImageUrls: string[];
  refetchServiceOffers: () => any;
  onClose: () => void;
}

function ServiceModal({
  data,
  getUrl,
  serviceOfferImageUrls,
  refetchServiceOffers,
  onClose,
}: IServiceModal) {
  const { enqueueSnackbar } = useSnackbar();
  const [editingData, setEditingData] = React.useState(data);
  const [saving, setSaving] = React.useState(false);
  // const [preview, setPreview] = React.useState(false);
  const isEdit = typeof editingData.offerId === 'number';
  const update = (d: Partial<IServiceOffer>) => setEditingData(prevState => ({...prevState, ...d}));
  const handleSave = async (silent = false) => {
    try {
      setSaving(true);
      const formData = new FormData();
      Object.entries(editingData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      const response = await axios.post(getUrl('manageServiceOffer'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setSaving(false);
      const offerId = response?.data?.offerId;
      if (typeof offerId === 'number') {
        update({offerId});
        !silent && enqueueSnackbar(`Successfully saved service offer ${offerId}!`, { variant: 'success' });
        await refetchServiceOffers();
      } else {
        !silent && enqueueSnackbar(`Couldn't save service offer. Please try again.`, { variant: 'error' });
      }
    } catch(e) {
      setSaving(false);
      !silent && enqueueSnackbar(`Couldn't save service offer. Please try again.`, { variant: 'error' });
    }
  };
  return (
    <>
      <Dialog onClose={onClose} aria-labelledby="service-modal-title" open fullWidth maxWidth="md">
        <DialogTitle id="service-modal-title" style={{color: '#fff'}} onClose={onClose}>
          {isEdit ? `Edit Offer ID ${editingData.offerId}` : 'Add Service Offer'}
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            autoFocus
            margin="normal"
            id="name"
            label="Name"
            fullWidth
            value={editingData.name}
            onChange={(e) => update({name: e.target.value})}
          />
          <TextField
            margin="normal"
            id="headline"
            label="Headline"
            fullWidth
            value={editingData.headline}
            onChange={(e) => update({headline: e.target.value})}
          />
          <TextField
            margin="normal"
            id="amount"
            label="Amount"
            fullWidth
            value={editingData.amount}
            onChange={(e) => update({amount: e.target.value})}
          />
          <TextField
            margin="normal"
            id="disclaimer"
            label="Disclaimer"
            fullWidth
            value={editingData.disclaimer}
            onChange={(e) => update({disclaimer: e.target.value})}
            multiline
          />
          <Autocomplete
            value={editingData.imageUrl}
            onChange={(_event, newValue) => update({imageUrl: newValue || ''})}
            filterOptions={(options, params) => {
              const filtered = imageUrlFilter(options, params);
              // Suggest the creation of a new value
              if (!filtered.includes(params.inputValue) && params.inputValue !== '') {
                filtered.push(params.inputValue);
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="image-url"
            options={serviceOfferImageUrls}
            fullWidth
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Image URL"
              />
            )}
          />
          <DropzoneArea
            acceptedFiles={['image/*']}
            dropzoneText="Click or drop an image to host"
            filesLimit={1}
            onDelete={() => update({imageUrl: ''})}
            onChange={async (files) => {
              const file = files[0];
              if (!file) {
                return;
              }
              try {
                const formData = new FormData();
                formData.append('fileName', file.name);
                formData.append('imageBase64', await getBase64(file));
                const response = await axios.post(getUrl('uploadServiceOfferImage'), formData, {
                  headers: {
                    'Content-Type': 'multipart/form-data'
                  }
                });
                const finalUrl = response?.data?.finalUrl;
                if (finalUrl) {
                  update({imageUrl: finalUrl});
                } else {
                  enqueueSnackbar(`Couldn't upload service offer image. Please try again.`, { variant: 'error' });
                }
              } catch(e) {
                enqueueSnackbar(`Couldn't upload service offer image. Please try again.`, { variant: 'error' });
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSave()} color="primary" disabled={saving}>
            {saving ? 'Saving...' : 'Save'}
          </Button>
          {/* <Button
            onClick={async () => {
              await handleSave(true);
              setPreview(true);
            }}
            color="primary"
            disabled={saving}
          >
            Preview
          </Button> */}
          <Button onClick={onClose} color="primary">
            Done
          </Button>
        </DialogActions>
      </Dialog>
      {/* {preview && (
        <ServiceOfferPreviewModal
          data={editingData}
          getUrl={getUrl}
          onClose={() => setPreview(false)}
        />
      )} */}
    </>
  );
}

// interface IServiceOfferPreviewModal {
//   data: IServiceOffer;
//   getUrl: (action: string, more?: string | undefined, dates?: boolean | undefined) => string;
//   onClose: () => void;
// }

// function ServiceOfferPreviewModal({
//   data,
//   getUrl,
//   onClose,
// }: IServiceOfferPreviewModal) {
//   const [canvas, setCanvas] = React.useState<any>();
//   const { enqueueSnackbar } = useSnackbar();
//   const [{ data: canvases }] = useAxios(getUrl('getAssets', '&type=canvas'));
//   const [{ data: preview }] = useAxios(getUrl('createPreview', `&canvasId=${canvas?.assetId}&serviceOfferIds=[${data.offerId}]&isImage=1`));
//   console.log('canvases', canvases);
//   return (
//     <Dialog onClose={onClose} aria-labelledby="service-modal-preview-title" open fullWidth maxWidth="sm">
//       <DialogTitle id="service-modal-preview-title" style={{color: '#fff'}} onClose={onClose}>
//         {`Preview Offer ID ${data.offerId}`}
//       </DialogTitle>
//       <DialogContent dividers>
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose} color="primary">
//           Done
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// }

function getBase64(file: File): Promise<string> {
  return new Promise<string> ((resolve,reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader?.result?.toString() || '');
    reader.onerror = error => reject(error);
  });
}