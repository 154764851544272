import React, { useEffect } from 'react';
import {ReactComponent as DashboardIcon} from '../../assets/dashboard.svg';
import {ReactComponent as AudiencesIcon} from '../../assets/audiences.svg';
import {ReactComponent as CampaignsIcon} from '../../assets/campaigns.svg';
import {ReactComponent as LeadsIcon} from '../../assets/leads.svg';
import {ReactComponent as OffersIcon} from '../../assets/offers.svg';
import {ReactComponent as AttributionsIcon} from '../../assets/attributions.svg';
import {ReactComponent as SettingsIcon} from '../../assets/settings.svg';
import { Drawer, Layout, Menu, Modal, Icon, Spin, message, Button } from 'antd';
import {
  Switch,
  Route,
  Link,
  BrowserRouter
} from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import ComingSoon from '../ComingSoon/ComingSoon';
import CampaignCreator from '../CampaignCreator/CampaignCreator';
import {Service} from '../Service/Service';
import { Login } from '../Login/Login';
import useAxios from 'axios-hooks';
import {axios} from '../../index';
import {API_HOST} from '../../data/urls';
import {ICampaign, IPromotionRecordData, ICartTotal, IDealerInfo, IServiceOffer, IPromotionData, DisplayMessage} from '../../interfaces';
import Select from 'react-select-virtualized';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import moment from 'moment';
import { CartListView } from '../../data';
import { v4 as uuidv4 } from 'uuid';
import { Orders } from '../Orders/Orders';
import OrderDetails from '../OrderDetails/OrderDetails';
import { jwtDecode } from 'jwt-decode';
import {ReactComponent as HelixLogoIcon} from '../../assets/helix-logo-symbol.svg'; 
import  AtomLogo  from '../../assets/atomLogo.png';

const { Header, Content, Sider } = Layout;

const defaultStartDate = moment().clone().startOf('month');
const defaultEndDate = moment().clone().subtract(1, 'days');
const defaultDateRange = [
  defaultStartDate.isAfter(defaultEndDate) ? moment().clone().subtract(1, 'months').startOf('month') : defaultStartDate,
  defaultEndDate
];

export const AppContext = React.createContext<{
  ucid:any;
  orders:any;
  salesforceJobList:any;
  cartDataFinal:any;
  editCartId:number;
  editReviseId:number;
  editReviseOpen:boolean;
  handleJobSelect:(jobDetails:any)=>void;
  campaignCreatorOpen: boolean;
  handleCampaignCreatorOpen: () => void;
  handleCampaignCreatorClose: (skipReset?: boolean) => void;
  orderHistoryOpen: boolean;
  handleOrderHistoryOpen: (value:boolean) => void;
  handleODBack: () => void;
  dealerInfo: IDealerInfo;
  updateCart:()=>void;
  editingCampaigns: ICampaign[];
  setEditingCampaigns: React.Dispatch<React.SetStateAction<ICampaign[]>>;
  getUrl: (action: string, more?: string, dates?: boolean) => string;
  checkedPromotions: number[];
  toggleCheckedPromotion: (inventoryWrapperId: number) => void;
  checkedServices: number[];
  toggleCheckedService: (offerId: number) => void;
  handleCheckOut: () => void;
  submitting: boolean;
  inventoryDataEntries: [string, IPromotionRecordData][];
  setInventoryDataEntries: React.Dispatch<React.SetStateAction<[string, IPromotionRecordData][]>>;
  cartTotalList: ICartTotal[];
  cartTotal: number;
  signature: string;
  setSignature: (signatureDataURL: string) => void;
  disclaimer: string;
  dateRange: moment.Moment[],
  setDateRange: React.Dispatch<React.SetStateAction<moment.Moment[]>>;
  removeCartCampaign: (cartId: string) => void;
  editCartCampaign:(cartId: string) => void;
  serviceWrappersData: IServiceOffer[];
  loadingServiceWrappers: boolean;
  refetchServiceOffers: () => any;
  setOrderHistoryLabel:(label:string)=>void;
  reloadSalesforceData:()=>void;
  handleCampaignReviseOpen:(jobdata:any)=>void;
  handleOrderHistoryClose:()=>void;
  seteditReviseOpen: (value:boolean) => void;
  displayMessage:DisplayMessage[];
  setDisplayMessage: React.Dispatch<React.SetStateAction<DisplayMessage[]>>;
  handleLogout:(val:any)=>void;
}>({
  ucid:0,
  orders:[],
  salesforceJobList:[],
  cartDataFinal:[],
  editCartId:0,
  editReviseId:0,
  editReviseOpen:false,
  handleJobSelect: () => {},
  campaignCreatorOpen: false,
  handleCampaignCreatorOpen: () => {},
  handleCampaignCreatorClose: () => {},
  orderHistoryOpen: false,
  handleOrderHistoryOpen: () => {},
  handleODBack: () => {},
  dealerInfo: {
    logo: '',
    partnerName: '',
    ucid: 0,
  },
  updateCart:()=>{},
  editingCampaigns: [],
  setEditingCampaigns: () => {},
  getUrl: () => '',
  checkedPromotions: [],
  toggleCheckedPromotion: () => {},
  checkedServices: [],
  toggleCheckedService: () => {},
  handleCheckOut: () => {},
  submitting: false,
  inventoryDataEntries: [],
  setInventoryDataEntries: () => {},
  cartTotalList: [],
  cartTotal: 0,
  signature: '',
  setSignature: () => {},
  disclaimer: '',
  dateRange: defaultDateRange,
  setDateRange: () => {},
  removeCartCampaign: () => {},
  editCartCampaign: () => {},
  serviceWrappersData: [],
  loadingServiceWrappers: false,
  refetchServiceOffers: () => {},
  setOrderHistoryLabel:() => {},
  reloadSalesforceData:()=>{},
  handleCampaignReviseOpen:()=>{},
  handleOrderHistoryClose:()=>{},
  seteditReviseOpen:()=>{},
  displayMessage:[],
  setDisplayMessage:()=>{},
  handleLogout:()=>{}
});

const AppLayout: React.FC = () => {
  const [apiKey, setApiKey] = useLocalStorage('apiKey');
  const [ucid, setUcid] = useLocalStorage('ucid');
  const [token, setToken] = useLocalStorage('token');
  const [inventoryDataEntries, setInventoryDataEntries] = React.useState<[string, IPromotionRecordData][]>([]);
  const [checkedPromotions, setCheckedPromotions] = React.useState<number[]>([]);
  const [checkedServices, setCheckedServices] = React.useState<number[]>([]);
  const [{ data: partners, loading, error }] = useAxios<IDealerInfo[]>(`https://${API_HOST}?apiKey=${apiKey}&do=getPartners`);
  const dealerInfo = React.useMemo(() => partners?.find((p) => `${p.ucid}` === `${ucid}`), [partners, ucid]);
  const [editCartId,setCartId] = React.useState(0);
  const [campaignCreatorOpen, setCampaignCreatorOpen] = React.useState(false);
  const [orderHistoryOpen,setOrderHistoryOpen] = React.useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(true);
  const [visible, setVisible] = React.useState(false);
  const [editingCampaigns, setEditingCampaigns] = React.useState<ICampaign[]>([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [signature, setSignature] = React.useState('');
  const [dateRange, setDateRange] = React.useState(defaultDateRange);
  const [selectedOrder,setSelectedOrder] = React.useState(''); 
  const getUrl = React.useCallback((action: string, more?: string, dates?: boolean) => {
    let url = `https://${API_HOST}?apiKey=${apiKey}&ucid=${ucid}&do=${action}${more || ''}`;
    if (dates) {
      url += `&startDate=${dateRange[0].format('YYYY-MM-DD')}&endDate=${dateRange[1].format('YYYY-MM-DD')}`;
    }
    return url;
  }, [token, ucid, dateRange]); //apiKey old

  const [{ data: serviceWrappersData, loading: loadingServiceWrappers, error: errorServiceWrappers}, refetchServiceOffers] = useAxios<IServiceOffer[]>(getUrl('getServiceOffers'));
  const [{ data: disclaimerData, loading: loadingDisclaimerData,error: errorDisclaimerData }] = useAxios(getUrl('getDisclaimer'));
  const [{data: salesforceJobList, loading: loadingSalesforceJobList, error: errorSalesforceJobList},reloadSalesforceData] = useAxios(getUrl('getJobNumbers')); 
  const [{data:cartData, loading: loadingCartData, error: errorCartData},refetchCartDetails] = useAxios(getUrl('getCartDetails','&userOnly=1&expirationDays=7'));
  const [{data:selectedOrderDetails, loading: loadingSelectedOrderDetails, error: errorOrderDetails},getSelectedOrderDetails] = useAxios(getUrl('OrderHistory',('&jobId='+selectedOrder)));
  const [{data:orders, loading: loadingOrders, error: errorOrders},reloadOrders] = useAxios(getUrl('OrderHistory'));
  const [orderHistoryLabel,setOrderHistoryLabel] = React.useState('');
  const [editReviseId,setEditReviseId] = React.useState(0);
  const [editReviseOpen,seteditReviseOpen] = React.useState(false);
  const [displayMessage, setDisplayMessage] = React.useState<DisplayMessage[]>([]);
  const [aiqLink, setAiqLink ] = React.useState(false);
  const [lifecycleLink, setlifecycleLink ] = React.useState(false);
  const [directLink, setDirectLink ] = React.useState(false);

  useEffect(()=>{
    if(selectedOrder){
    getSelectedOrderDetails();
    }
  },[selectedOrder]);


  useEffect(()=>{
    if(token!=null && token!=''){
      let tokenVal:any = jwtDecode(token) ;
      if(tokenVal.is_helix_user)
        setAiqLink(true);
      if(tokenVal.is_amp_user)
        setlifecycleLink(true);
      if(tokenVal.is_direct_user)
        setDirectLink(true);
    }
  },[token]);

 let allSelectedInventiries:any = [];
  let cartDataFinal = cartData?.data?.map((item:any)=>{
  item.cartInfo[0].cartId = item.cartId;
  allSelectedInventiries.push(item.cartInfo[0].inventorySetIds)
    return item.cartInfo[0];
 });
  let allWrappers:any = localStorage.getItem('allWrappers');
  allWrappers = allWrappers?JSON.parse(allWrappers):[];
  

  let  [{data:inventoryData, loading: loadingInventoryData, error: errorInventoryData}]= useAxios<IPromotionData>(getUrl('getInventoryPromotionData', `&inventoryWrapperIds=${JSON.stringify(allWrappers[0])}`));
  let temp:any = {};

   for(let x in inventoryData?.inventoryData){
      let pr = allWrappers[1]?.all.find((item:any)=>item.inventoryWrapperName === x);
      temp[x] = inventoryData?.inventoryData[x];
      temp[x].inventoryWrapperId = pr?.inventoryWrapperId;
   }

  let inventories = temp?Object.entries(temp).map(([title, value]:any) => {
      return [
        title,
        { ...value }
      ] as [string, IPromotionRecordData]
    }):[];


  const [cartTotalList, cartTotal] = React.useMemo(() => {
    const cartTotalListX: ICartTotal[] = [];
    let cartTotalX = 0;

    cartDataFinal?.forEach((cartCampaign:any) => {
      const inventoryData = inventories?.find((entry) => entry[1]?.inventoryWrapperId === cartCampaign?.inventoryWrapperId);
      if (inventoryData) {
        const [title, inventoryRecord] = inventoryData;
        const selectedInventorySets = inventoryRecord.inventorySets.filter(s => cartCampaign.inventorySetIds.includes(s.inventorySetId));
        const selectedInventorySetsPrices = inventoryRecord.packages.find(p => p.packageId === cartCampaign.packageId)?.pricing.filter(s => cartCampaign.inventorySetIds.includes(s.inventorySetId));
        if (!selectedInventorySets?.length) {
          return;
        }
        const totals = selectedInventorySets.reduce((t, v, ti) => {
          return {
            uuid: cartCampaign.uuid,
            title,
            icon: inventoryRecord.icon,
            cost: t.cost + (selectedInventorySetsPrices?.find(p => p.inventorySetId === v.inventorySetId)?.price || 0),
            audiences: ti + 1,
            vehicles: t.vehicles + v.numberToPromote,
            subscribed: cartCampaign.subscribed,
            cartId:cartCampaign.cartId
          };
        }, {
          uuid: '',
          title: '',
          icon: '',
          cost: 0,
          audiences: 0,
          vehicles: 0,
          subscribed: false,
          cartId:0,
        });
        cartTotalX += totals.cost;
        cartTotalListX.push(totals);
      }
    });

    return [cartTotalListX, cartTotalX];
  }, [inventories, cartDataFinal]);
  
  const handleLogout = async(val:any='') => {
    const currentDomain = window.location.hostname;
    let redirectUrl = 'https://login.aiqauto.com/'+ (val==''?'':'?source=drive_test');
    // Check if the current domain is "drive.video"
    if (currentDomain.toLowerCase() === 'my.drive.video') {
      redirectUrl = 'https://login.aiqauto.com/'+ (val==''?'':'?source=drive');
    } 
    const response = await axios.get(`https://${API_HOST}?do=logout&token=`+ token,{
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    setUcid('');
    setToken('');
    window.location.href = redirectUrl;
  };

  useEffect(()=>{
    if (`${error}`.includes('401') 
        || `${errorServiceWrappers}`.includes('401')
        || `${errorDisclaimerData}`.includes('401')
        || `${errorSalesforceJobList}`.includes('401')
        || `${errorCartData}`.includes('401')
        || `${errorOrderDetails}`.includes('401')
        || `${errorOrders}`.includes('401')
        || `${errorInventoryData}`.includes('401')
      ) { 
        handleLogout('')
    }
  },[error,errorServiceWrappers,errorDisclaimerData,errorSalesforceJobList,errorCartData,errorOrderDetails,errorOrders,errorInventoryData]);


  if (`${error}`.includes('401') || !ucid || (ucid && !dealerInfo)) {
    return (
      <Login
        partners={token ? partners : []}   //apiKey old
        loading={loading}
        setApiKey={setApiKey}
        setUcid={setUcid}
        setToken={setToken}
        token={token}
        error={error}
        handleLogout={handleLogout}
      />
    );
  }
  if (loading) {
    return <Spin className="main-spinner" size="large" indicator={<Icon type="loading" spin />} />;
  }
  if (error || !dealerInfo) {
    return (
      <h1 className="main-error-message">Oops.. something went wrong.. Please try again.</h1>
    );
  }
  const handleCampaignCreatorOpen = () => {
  
      setEditingCampaigns(prevState => checkedPromotions.map(inventoryWrapperId => ({
        inventorySetIds: [],
        packageId: null,
        subscribed: false,
        startDate: '',
        endDate: '',
        evergreenVideoId: null,
        canvasId: null,
        imageId1:null,
        imageId2:null,
        publisherOptions: [],
        ...prevState.find(p => p.inventoryWrapperId === inventoryWrapperId),
        uuid: uuidv4(),
        inventoryWrapperId,
        budgetJobId:'',
        priceLabel:"",
      })));
    
  
    setCampaignCreatorOpen(true);
  };
  const handleCampaignCreatorClose = (skipReset?: boolean) => {
    if (!skipReset) {
      setCheckedPromotions([]);
    }
    setCampaignCreatorOpen(false);
    setCartId(0);

    if (editReviseId) {
      setCheckedPromotions([]);
      setEditReviseId(0);
      setEditingCampaigns([]);
    }
    
  };
  const handleOrderHistoryOpen = (value:boolean) => {
    setOrderHistoryOpen(value);
    setOrderHistoryLabel('Order History');
  };
  const handleODBack = () => {
    setSelectedOrder('');
  }
  const handleOrderHistoryClose = () => {
      setOrderHistoryOpen(false);
      setSelectedOrder('');
  };
  const updateCart = () => {
    refetchCartDetails();
  }
  const removeCartCampaign = (cartId: string) => {
    axios.get(getUrl('saveCartCampaign','&cartId='+cartId+'&delete=1'));
    updateCart();
  };
  const deleteCart = async () => {
   let allCarts:any = [];
     cartDataFinal?.map((item:any)=>{
      allCarts.push(item.cartId);
    });
    if(allCarts?.length){
     axios.get(getUrl('saveCartCampaign','&cartId='+allCarts+'&delete=1'));
    }

  }

  const handleJobSelect =async (jobDetails:any) => {
      setSelectedOrder(jobDetails.row.jobId);
  }

  const editCartCampaign = (cartId:string) => {
    setCartId(Number(cartId));
    let wrapper = 0;
      cartDataFinal.map((item:any) => {
      if(item.cartId == cartId){
        wrapper = item.inventoryWrapperId;
      }
    })
    if(wrapper>0){
      setCheckedPromotions([wrapper]);
      handleCampaignCreatorOpen();
      let selectedCamp:any = {};
        cartDataFinal.map((item:any)=>{
          if(item.cartId == cartId){
              selectedCamp = item;
          }
        });
        setEditingCampaigns([{
          inventorySetIds: (selectedCamp?.inventorySetIds)?selectedCamp?.inventorySetIds:[],
          packageId: (selectedCamp?.packageId)?selectedCamp?.packageId:null,
          subscribed: (selectedCamp?.subscribed)?selectedCamp?.subscribed:false,
          startDate: (selectedCamp?.startDate)?selectedCamp?.startDate:'',
          endDate: (selectedCamp?.endDate)?selectedCamp?.endDate:'',
          evergreenVideoId: (selectedCamp?.evergreenVideoId)?selectedCamp?.evergreenVideoId:null,
          canvasId: (selectedCamp?.canvasId)?selectedCamp?.canvasId:null,
          imageId1:(selectedCamp?.imageId1)?selectedCamp?.imageId1:null,
          imageId2:(selectedCamp?.imageId2)?selectedCamp?.imageId2:null,
          publisherOptions: (selectedCamp?.publisherOptions)?selectedCamp?.publisherOptions:[],
          uuid: (selectedCamp?.uuid)?selectedCamp?.uuid:uuidv4(),
          inventoryWrapperId:(selectedCamp?.inventoryWrapperId)?selectedCamp?.inventoryWrapperId:wrapper,
          budgetJobId:(selectedCamp?.budgetJobId)?selectedCamp?.budgetJobId:'',
          priceLabel:(selectedCamp?.priceLabel)?selectedCamp?.priceLabel:'',
        }]);
    setCampaignCreatorOpen(true);

      
    }
  };
  const toggleCheckedPromotion = (inventoryWrapperId: number) => {
    setCheckedPromotions(
      prevState => prevState.includes(inventoryWrapperId)
        ? prevState.filter(i => i !== inventoryWrapperId)
        : [...prevState, inventoryWrapperId].sort()
    );
  };
  const toggleCheckedService = (offerId: number) => {
    setCheckedServices(
      prevState => prevState.includes(offerId)
        ? prevState.filter(i => i !== offerId)
        : [...prevState, offerId].sort()
    );
  };
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const formData = new FormData();
      formData.append('information', JSON.stringify({
        campaigns: cartDataFinal.map((cc:any) => ({
          ...cc,
          budget: cartTotalList.find(ct => ct.uuid === cc.uuid)?.cost || 0,
        })),
        budget: cartTotal,
        signature
      }));
      await axios.post(getUrl('sendPurchaseOrder'), formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      setShowCheckoutModal(false);
      setSubmitting(false);
      setShowSuccessModal(true);
      
    } catch(e) {
      setSubmitting(false);
      message.error('Oops! Couldn\'t save. Please try again.');
    }
  };
  const handleCheckOut = () => {
    setShowCheckoutModal(true);
  };
  const handleCloseConfirm = async () => {
    setSignature('');
    setCheckedPromotions([]);
    setShowSuccessModal(false);
    await deleteCart();
    updateCart();
    reloadSalesforceData();
    reloadOrders();
  };
  
  const onPartnerSelect = (option: any) => {
    setCheckedPromotions([]);
    setUcid(option.value);
    setSignature('');
  };

  const  handleCampaignReviseOpen = async (jobdata:any) => {
    let response:any;
    let selectedReviseDetails:any;
    seteditReviseOpen(true);
    
    response =  await axios.post(getUrl('getJobOrderHistory', `&jobId=${JSON.stringify(jobdata.row.jobId)}`));
    selectedReviseDetails = response.data.data;
    setEditReviseId(jobdata.row.jobId);
    //setCheckedPromotions([selectedReviseDetails.inventoryWrapperId]); 
    setCheckedPromotions(allWrappers[0]);  
    console.log(selectedReviseDetails);
    setEditingCampaigns(allWrappers[0].map((val:any,index:number)=>{
      return ({
        inventorySetIds: val==selectedReviseDetails.inventoryWrapperId? (selectedReviseDetails?.inventorySetIds)?selectedReviseDetails?.inventorySetIds:[]:[],
        packageId: (selectedReviseDetails?.packageId)?selectedReviseDetails?.packageId:null,
        subscribed: (selectedReviseDetails?.subscribed)?selectedReviseDetails?.subscribed:false,
        startDate: (selectedReviseDetails?.startDate)?selectedReviseDetails?.startDate:'',
        endDate: (selectedReviseDetails?.subscribed)?'':(selectedReviseDetails?.endDate)?selectedReviseDetails?.endDate:'',
        evergreenVideoId: (selectedReviseDetails?.evergreenVideoId)?selectedReviseDetails?.evergreenVideoId:null,
        canvasId: (selectedReviseDetails?.canvasId)?selectedReviseDetails?.canvasId:null,
        imageId1:(selectedReviseDetails?.imageId1)?selectedReviseDetails?.imageId1:null,
        imageId2:(selectedReviseDetails?.imageId2)?selectedReviseDetails?.imageId2:null,
        publisherOptions: (selectedReviseDetails?.publisherOptions)?selectedReviseDetails?.publisherOptions:[],
        uuid: (selectedReviseDetails?.uuid)?selectedReviseDetails?.uuid:uuidv4(),
        inventoryWrapperId:val==selectedReviseDetails.inventoryWrapperId ? (selectedReviseDetails?.inventoryWrapperId)?selectedReviseDetails?.inventoryWrapperId:'':val,
        budgetJobId:(selectedReviseDetails?.budgetJobId)?selectedReviseDetails?.budgetJobId:'',
        priceLabel:(selectedReviseDetails?.priceLabel)?selectedReviseDetails?.priceLabel:'',
      })
    }
    ));

    seteditReviseOpen(false); 
    setCampaignCreatorOpen(true);
};

  const context = {
    ucid,
    orders,
    salesforceJobList,
    cartDataFinal,
    editCartId,
    editReviseId,
    editReviseOpen,
    handleJobSelect,
    campaignCreatorOpen,
    handleCampaignCreatorClose,
    handleCampaignCreatorOpen,
    orderHistoryOpen,
    handleOrderHistoryOpen,
    handleODBack,
    dealerInfo,
    updateCart,
    editingCampaigns,
    setEditingCampaigns,
    getUrl,
    checkedPromotions,
    toggleCheckedPromotion,
    handleCheckOut,
    submitting,
    inventoryDataEntries,
    setInventoryDataEntries,
    cartTotalList,
    cartTotal,
    signature,
    setSignature,
    disclaimer: disclaimerData?.disclaimer || '',
    dateRange,
    setDateRange,
    removeCartCampaign,
    editCartCampaign,
    checkedServices,
    toggleCheckedService,
    serviceWrappersData,
    loadingServiceWrappers,
    refetchServiceOffers,
    setOrderHistoryLabel,
    reloadSalesforceData,
    handleCampaignReviseOpen,
    handleOrderHistoryClose,
    seteditReviseOpen,
    displayMessage,
    setDisplayMessage,
    handleLogout,
  };
  
  const handleRedirect = (event:any,value:any) => {
    if(token!=null && token!=''){
      const currentDomain = window.location.hostname;
            let aiqDomain = 'https://aiqauto.com/';
            let directDomain = 'https://myatomdirect.com/';
            let lifecycleDomain = 'https://atom.gsm.auto/';
            // Check if the current domain is "dev.drive.video"
            if (currentDomain.toLowerCase() === 'dev.drive.video') {
                aiqDomain = 'https://facelift.drivehelix.com/';
                lifecycleDomain = 'https://staging.gsm.auto/';
                directDomain = 'https://staging.myatomdirect.com/Default.aspx'
            } 
            switch(value){
              case 'aiq':
                window.location.href  = aiqDomain + "scripts/authLanding.php?token=" + token + "&ucid=" + ucid
                break;
              case 'lifecycle':
                window.location.href = lifecycleDomain + "login/gsm2#token=" + token 
                break;
              case 'direct':
                window.location.href = directDomain + "?token=" + token
                break;
              default:
                window.location.href = ''
            } 
    }
  }

  return (
    <AppContext.Provider value={context}>
      <Layout className="app-layout">
        <Sider collapsible collapsed={collapsed} onCollapse={setCollapsed} width={150}>
          <a className="app-layout-logo" href="https://drivehelix.com/?v=leads">
            <CampaignsIcon />
          </a>
          <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
            <Menu.Item key="1">
              <Link to="/">
                <Icon component={DashboardIcon} />
                <span>Dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to="/audiences">
                <Icon component={AudiencesIcon} />
                <span>Audiences</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <a href="https://www.youtube.com/channel/UCbY7NbzoGgAU7aUkB4dzK5g">
                <Icon component={CampaignsIcon} />
                <span>Campaigns</span>
              </a>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to="/leads">
                <Icon component={LeadsIcon} />
                <span>Leads</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to="/offers">
                <Icon component={OffersIcon} />
                <span>Offers</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="6">
              <Link to="/attribution">
                <Icon component={AttributionsIcon} />
                <span>Attribution</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="7">
              <Link to="/settings">
                <Icon component={SettingsIcon} />
                <span>Settings</span>
              </Link>
            </Menu.Item>
            {/* <Menu.Item key="8">
              <Link to="/orders">
                <Icon component={Orders} />
                <span>Order History</span>
              </Link>
            </Menu.Item> */}
            
          </Menu>
        </Sider>
        <Layout>
          <Header>
            <div className="dealer-info">
              <CampaignsIcon />
              <Select
                className="partner-autocomplete"
                isClearable={false}
                options={partners?.map(p => ({label: `${p.partnerName} (${p.ucid})`, value: p.ucid}))}
                onChange={onPartnerSelect}
                defaultValue={{label: `${dealerInfo.partnerName} (${dealerInfo.ucid})`, value: dealerInfo.ucid}}
              />
               
               {aiqLink && <Button
                  type="default"
                  shape="round"
                  size="small"
                  className='custom-header-bt'
                  style={{marginRight:'10px',display:'flex'}}
                  onClick={(event) => {
                    handleRedirect(event,'aiq');
                  }}
                ><HelixLogoIcon style={{height:34, marginTop:3}}/>
                 <div style={{marginTop:6}}>Audience IQ </div>
              </Button>
               }
              {lifecycleLink && <Button
                  type="default"
                  shape="round"
                  size="small"
                  className='custom-header-bt'
                  style={{marginRight:'10px'}}
                  onClick={(event) => {
                    handleRedirect(event,'lifecycle');
                  }}
                > <img style={{height:32, width:32, marginRight:8}} src={AtomLogo} />
                  Lifecycle
              </Button>
              }
              {directLink && <Button
                  type="default"
                  shape="round"
                  size="small"
                  className='custom-header-bt'
                  onClick={(event) => {
                    handleRedirect(event,'direct');
                  }}
                > <img style={{height:32, width:32, marginRight:8}} src={AtomLogo} />
                  Direct
               </Button>
              }
            </div>
            <div>
              <Icon type="alert" />
              <Icon type="setting" />
              <Icon type="search" />
              <Icon type="logout" onClick={() => handleLogout('')} />
              <Icon type="menu-fold" onClick={() => setVisible(true)} />
            </div>
          </Header>
          <Content>
          <BrowserRouter>
            <Switch>
              <Route exact path="/audiences">
                <ComingSoon />
              </Route>
              <Route exact path="/campaigns">
                <ComingSoon />
              </Route>
              <Route exact path="/leads">
                <ComingSoon />
              </Route>
              <Route exact path="/offers">
                <ComingSoon />
              </Route>
              <Route exact path="/attribution">
                <ComingSoon />
              </Route>
              <Route exact path="/settings">
                <ComingSoon />
              </Route>
              <Route exact path="/service">
                <Service />
              </Route>
              <Route  path="/orders">
                <Orders />
              </Route>
              <Route exact path="/">
                <Dashboard />
              </Route>
            </Switch>
            </BrowserRouter>
          </Content>
          {/* <Footer>
            <div>
              <Icon type="home" />
              <Icon type="share-alt" />
              <Icon type="user" />
              <Icon type="dollar" />
            </div>
          </Footer> */}
        </Layout>
        <Drawer
          placement="right"
          closable={false}
          onClose={() => setVisible(false)}
          visible={visible}
        >
          <p><Icon type="alert" />&nbsp;&nbsp;Alerts</p>
          <p><Icon type="setting" />&nbsp;&nbsp;Settings</p>
          <p><Icon type="search" />&nbsp;&nbsp;Search</p>
        </Drawer> 
        {editReviseOpen && (
        <div className="loading-spinner custom-loading">
          <Spin  size="large" indicator={<Icon type="loading" spin />} />
        </div>)
        }
        {campaignCreatorOpen && <CampaignCreator />}
        {showCheckoutModal && (
          <Modal
            className="success-modal-root"
            visible={true}
            title={(
              <>
                <Icon type="shopping-cart" />&nbsp;&nbsp;
                Check Out
              </>
            )}
            onCancel={() => setShowCheckoutModal(false)}
            footer={(
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={submitting}
                disabled={!signature || cartTotalList.length === 0}
              >
                Submit
              </Button>
            )}
            width={500}
          >
            <section className="success-modal-container">
              <CartListView
                className="campaign-review-cart"
                data={cartTotalList}
                total={cartTotal}
                signature={signature}
                onSign={setSignature}
              />
            </section>
          </Modal>
        )}
        {showSuccessModal && (
          <Modal
            className="success-modal-root"
            visible={true}
            title="Your Order"
            onCancel={handleCloseConfirm}
            footer={(
              <Button
                type="primary"
                onClick={handleCloseConfirm}
              >
                Continue Promoting
              </Button>
            )}
            width={500}
          >
            <section className="success-modal-container">
              <h2>
                <Icon type="check-circle" />&nbsp;&nbsp;
                Your order was successful.
              </h2>
              <p>
                <strong>Thanks for your order!</strong><br />
                You will receive a verification email with your invoice for this purchase along with next steps for tracking your new campaigns.
                <br />
                <br />
                <a href="https://drivehelix.com/landing/contact-us.html" target="_blank" rel="noopener noreferrer">
                  Contact Us
                </a> with questions.
              </p>
              <CartListView
                className="campaign-review-cart"
                data={cartTotalList}
                total={cartTotal}
                signature={signature}
                onSign={setSignature}
                showSignature={false}
              />
            </section>
          </Modal>
        )}
        {orderHistoryOpen &&
              <Modal
              className="campaign-creator-root custom-pop"
              visible={true}
              title={selectedOrder?"Order Details":orderHistoryLabel}
              onCancel={handleOrderHistoryClose}
              footer={null} 
            > {selectedOrder?<OrderDetails order={selectedOrderDetails} />:<Orders/>}</Modal>}
      </Layout>
    </AppContext.Provider>
  );
}

export default AppLayout;
