import React from 'react';

const ComingSoon = () => {
  return (
    <div style={{
      color: '#fff',
      textAlign: 'center',
      position: 'absolute',
      top: '50%',
      left: 'calc(50% - 1.5rem)',
      fontSize: '3rem',
    }}>
      Coming Soon...
    </div>
  );
}

export default ComingSoon;
