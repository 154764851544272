import React from 'react';

export function useLocalStorage(key: string) {
  const [storedValue, setStoredValue] = React.useState<string | null>(() => {
    try {
      return window.localStorage.getItem(key);
    } catch (error) {
      console.log(error);
      return null;
    }
  });

  const setValue = (value: string) => {
    try {
      setStoredValue(value);
      window.localStorage.setItem(key, value);
    } catch (error) {
      console.log(error);
    }
  };
  return [storedValue, setValue] as const;
}
