/* eslint-disable */

import React, { useEffect } from "react";
import { Select, Button, Radio, List, Checkbox, Avatar } from "antd";
import { AppContext } from "../AppLayout/AppLayout";
import { DatePicker, Switch } from "@patternfly/react-core";
import { addBusinessDays } from "date-fns";
import useAxios from "axios-hooks";
import {
  IPromotionData,
  IPromotionRecordData,
  TInventory,
} from "../../interfaces";
import { lowerCase } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { width } from "@mui/system";
import "../../styles/components/CloneCreator.scss";
import { CircularProgress } from "@material-ui/core";
import { axios } from "../..";
import { priceLabel } from '../../data';




const CheckUncheckAll = (props: any) => {
  return (
    <div className="header-select">
      <label className="ant-list-item-meta-title" style={{float:'left'}}>Price Label {' '}
        <Select value={props?.priceLabel} onChange={(v) => props.onChangePrice(v)}>
            {priceLabel?.map((item:any) => {
                 return <Select.Option key={item?.id} value={item?.id}>{item?.value}</Select.Option>
            })} 
        </Select>
      </label>
      <label className="ant-list-item-meta-title">
        {props.checkStatus ? "Unselect All " : "Select All "}
        <Checkbox
          id="selectAll"
          onClick={props.onCheckAll}
          checked={props.checkStatus}
        />
      </label>
    </div>
  );
};

interface CloneCreatorProps {
  order?: any;
  handleCloneBack: () => void;
}

const CloneCreator = ({ order, handleCloneBack }: CloneCreatorProps) => {
 
  const { ucid,salesforceJobList, getUrl,setOrderHistoryLabel,updateCart,handleOrderHistoryOpen,reloadSalesforceData,handleLogout } = React.useContext(AppContext);
  const [screen, setScreen] = React.useState(false);
  const [priceLabel, setPriceLabel] = React.useState(1);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [subscribed, setSubscribed] = React.useState(false);
  const [budgetJobId, setBudgetJobId] = React.useState(salesforceJobList?(salesforceJobList[0]?.jobId):"");
  // const [checkAll, setCheckAll] = React.useState(selectedIds == allIds ? true : false);
  const [inventoryFilter, setInventoryFilter] = React.useState<string>();
  const [inventorySearch] = React.useState("");
  const nextDisabled = !(
    startDate.length > 0 &&
    (endDate.length > 0 || subscribed) &&
    budgetJobId.toString().length > 0
  );
  const [wrapperSelected, setWrapperSelected] = React.useState(0);
  const [{ data: inventoryWrappersData, loading: loadingInventoryWrappers, error: errorInventoryWrappers}] =
    useAxios<TInventory>(getUrl("getInventoryWrappers"));
  const inventoryFilters = inventoryWrappersData
    ? Object.keys(inventoryWrappersData)
    : [];
  const inventoryFilterValue = inventoryFilter || inventoryFilters[0];
  const [
    { data: inventoryPromotionData, loading: loadingInventoryPromotionData, error:errorInventoryPromotionData },
    getPromotionData,
  ] = useAxios<IPromotionData>(
    getUrl(
      "getInventoryPromotionData",
      `&inventoryWrapperIds=${JSON.stringify([wrapperSelected])}&isJobEdit=0}`
    )
  );
  const [{ data: itemToClone, loading: loadingItemToCLone, error:errorItemToCLone }] = useAxios(
    getUrl("getJobOrderHistory", `&jobId=${order?.jobId}`)
  );
  const handleChange = (joibId: any) => {
    setBudgetJobId(joibId);
  };
  const dateFormat = (date: Date) =>
    date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  const dateParse = (date: any) => {
    const split = date.split("/");
    if (split.length !== 3) {
      return new Date();
    }
    let month = split[0];
    let day = split[1];
    let year = split[2];
    return new Date(
      `${year.padStart(4, "0")}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}T00:00:00`
    );
  };
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  const minDate = addBusinessDays(new Date(mm + '/' + dd + '/' + yyyy), 2);
  //const minDate = addBusinessDays(new Date(), 3);
  const startDateValidator = (date: Date) => {
    if (date < minDate) {
      return "Date is before the allowable range.";
    }
    return "";
  };
  const endDateValidator = (startDate: string) => {
    const sd = dateParse(startDate);
    return (date: Date) => {
      if (date < sd) {
        return "Date is before the start date.";
      }
      return startDateValidator(date);
    };
  };

  const onWrapperChange = async (event: any) => {
    setWrapperSelected(event.target.value);
  };

  let inventories: any = inventoryPromotionData
    ? Object.values(inventoryPromotionData?.inventoryData)[0]?.inventorySets
    : [];
  const allIds = inventories?.map((s: any) => s.inventorySetId);
  const [selectedInventorySetIds, setSelectedInventroy] = React.useState<any>(
    []
  );
  const selectedIds = selectedInventorySetIds;
  const [selectAllFlag, setAllFlag] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(
    selectedIds == allIds ? true : false
  );
  const handleCheckAll = () => {
    checkAll ? setCheckAll(false) : setCheckAll(true);
    setAllFlag(true);
  };
  const removeInventorySetId = (inventorySetId: any) => {
    setSelectedInventroy(
      selectedInventorySetIds?.filter((i: any) => i !== inventorySetId)
    );
    setCheckAll(false);
  };
  const addInventorySetId = (inventorySetId: any) =>
    setSelectedInventroy([...selectedInventorySetIds, inventorySetId]);

  React.useEffect(() => {
    if (selectAllFlag) {
      const allIds = inventories.map((s: any) => s.inventorySetId);
      inventories = checkAll ? (allIds ? allIds : []) : [];

      setSelectedInventroy(inventories);
      setAllFlag(false);
    }
  }, [selectAllFlag]);

  const cloneCampaign = async () => {
    
    let currentCampaignClient = {
      budgetJobId: budgetJobId,
      canvasId: itemToClone?.data.canvasId,
      endDate: endDate ? endDate : "",
      evergreenVideoId: itemToClone?.data.evergreenVideoId,
      imageId1: itemToClone?.data.imageId1,
      imageId2: itemToClone?.data.imageId2,
      inventorySetIds: selectedInventorySetIds,
      inventoryWrapperId: wrapperSelected,
      packageId: itemToClone?.data.packageId,
      publisherOptions: itemToClone?.data.publisherOptions,
      startDate: startDate,
      subscribed: subscribed,
      uuid: uuidv4(),
      priceLabel:priceLabel
    };
    console.log(currentCampaignClient);
    console.log(order);
    console.log(itemToClone);
    await axios.post(getUrl('saveCartCampaign', `&cartInfo=[${JSON.stringify(currentCampaignClient)}]`));
    updateCart();
    reloadSalesforceData();
    handleOrderHistoryOpen(false);
  };

  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
    color: "white",
  };

  useEffect(()=>{
    if (`${errorInventoryWrappers}`.includes('401') 
        || `${errorInventoryPromotionData}`.includes('401')
        || `${errorItemToCLone}`.includes('401')
      ) { 
        handleLogout('')
    }
  },[errorInventoryWrappers, errorInventoryPromotionData, errorItemToCLone]);


  return !screen ? (
    <>
      {/* {setOrderHistoryLabel('Select Salesforce Job and Duration')} */}

    <div style={{display:'flex',marginTop:'30px'}}>
      <div style={{flex:'4',textAlign:'right',marginRight:'10px',fontSize:'16px'}}>Salesforce Job</div>
      <div style={{flex:'4'}}>
      <Select
        className="ant-select"
        value={budgetJobId}
        onChange={(item: any) => handleChange(item)}
      >
        {salesforceJobList?.map((item: any) => {
          if (item) {
            return (
              <Select.Option key={item?.jobId} value={item?.jobId}>
                {item?.jobNumber}
              </Select.Option>
            );
          }
        })}
      </Select>
      </div>

    </div>
    

      <div style={{marginTop:'30px'}} className="schedule-step">
        <h3>Select Schedule</h3>
        <div>
          <span style={{marginRight:'20px'}}>Start Date</span>
          <DatePicker
            value={startDate}
            placeholder="MM/DD/YYYY"
            dateFormat={dateFormat}
            dateParse={dateParse}
            validators={[startDateValidator]}
            onChange={(v) => setStartDate(v)}
          />
        </div>
        <div style={{marginTop:'15px'}}>
          <span style={{marginRight:'20px'}}>End Date</span>
          <DatePicker
            value={endDate}
            placeholder="MM/DD/YYYY"
            dateFormat={dateFormat}
            dateParse={dateParse}
            isDisabled={subscribed}
            validators={[endDateValidator(startDate)]}
            onChange={(v) => setEndDate(v)}
          />
        </div>
        <Switch
          label="Subscribed!"
          labelOff="Subscribe?"
          isChecked={subscribed}
          onChange={(checked) => {
            setSubscribed(checked);
            setEndDate("");
          }}
        />
        <br/><br/>
          By subscribing, your campaign will continue to run in this mode until
          you switch off.
      </div>
      <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
      <Button
        type="primary"
        style={{marginRight:'25px'}}
        onClick={() => {
            handleCloneBack();
        }}
      >
        Back
      </Button>
      <Button
        type="primary"
        disabled={nextDisabled}
        onClick={() => {
          setScreen(true);
        }}
      >
        Next
      </Button>
      </div>
    </>
  ) : wrapperSelected == 0 ? (
    <>
      {setOrderHistoryLabel('Select Inventory')}

      <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
          <span>Filter:&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <span style={{width:'200px',display:'inline'}}>
          <Select
            value={inventoryFilterValue}
           className="ant-select"
            onChange={setInventoryFilter}
          >
            {inventoryFilters.map((v) => (
              <Select.Option key={v} value={v}>
                <span style={{ textTransform: "capitalize" }}>{v}</span>
              </Select.Option>
            ))}
          </Select>
          </span>
      </div>
      <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
      <div style={{width:'70%',display:'flex',justifyContent:'center'}}>
      <Radio.Group onChange={onWrapperChange} value={wrapperSelected}>
        {inventoryWrappersData?.[inventoryFilterValue]
          ?.filter((i) =>
            lowerCase(i.inventoryWrapperName).includes(
              lowerCase(inventorySearch)
            )
          )
          .map((item) => {
            return (
              <Radio style={radioStyle} value={item.inventoryWrapperId}>
                {item.inventoryWrapperName}
              </Radio>
            );
          })}
      </Radio.Group>
      </div>
      </div>
      <div style={{display:'flex',justifyContent:'center',marginTop:'20px'}}>
      <Button
        type="primary"
        onClick={() => {
          setScreen(false);
        }}
      >
        Back
      </Button>
      </div>
      
    </>
  ) : (
    <>
    {setOrderHistoryLabel('Select Inventory')}
      {
        <List
          itemLayout="horizontal"
          dataSource={inventories}
          header={
            <CheckUncheckAll
              onCheckAll={handleCheckAll}
              checkStatus={checkAll}
              priceLabel={priceLabel}
              onChangePrice={setPriceLabel}
            />
          }
          renderItem={(item: any) => {
            const checked = selectedInventorySetIds.includes(
              item.inventorySetId
            );
            return (
              <List.Item
                actions={[<Checkbox checked={checked} />]}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  checked
                    ? removeInventorySetId(item.inventorySetId)
                    : addInventorySetId(item.inventorySetId)
                }
              >
                <List.Item.Meta
                  avatar={
                    <Avatar
                      size={75}
                      icon={<img src={item.icon} alt={item.title} />}
                    />
                  }
                  title={item.title}
                  description={
                    <div className="car-list-extra">
                      {item.bullets.map(([a, b]: any, i: any) => (
                        <span key={i}>
                          {a}:&nbsp;&nbsp;<strong>{b}</strong>
                          <br />
                        </span>
                      ))}
                    </div>
                  }
                />
              </List.Item>
            );
          }}
        />
      }
      <div style={{display:'flex',justifyContent:'center'}}>
      <Button
      style={{marginRight:'15px'}}
        type="primary"
        onClick={() => {
          // setScreen(false);
          setWrapperSelected(0);
          setCheckAll(false);
        }}
      >
        Back
      </Button>
      <Button
        type="primary"
        onClick={() => {
          cloneCampaign();
        }}
      >
        Clone
      </Button>
      </div>
      {(loadingInventoryPromotionData) && (
        <div className="uploading-video-progress">
          <CircularProgress color="secondary" size={100} />
        </div>
      )}
    </>
  );
};
export default CloneCreator;
