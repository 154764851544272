export interface ICampaign {
  inventoryWrapperId: number;
  inventorySetIds: number[];
  packageId: number | null;
  subscribed: boolean;
  startDate: string;
  endDate: string;
  evergreenVideoId: number | null;
  canvasId: number | null;
  imageId1: number | null;
  imageId2: number | null;
  uuid: string;
  publisherOptions: {
    channelId: number;
    finalUrl: string | null;
    campaignId: string | null;
    adGroupId: string | null;
  }[];
  budgetJobId:string | null | undefined;
  priceLabel:string | null;
};

export enum Monitors {
  YouTube = 1,
  Facebook = 2,
  Instagram = 3,
  CTV = 4,
  'In-App' = 5,
  'Premium Websites' = 6,
};

export interface ILiveMonitor {
  date: string;
  channels: {
    [key in keyof typeof Monitors]: {
      impressions: number;
      budget: number;
      views: number;
    }
  }
}

export interface ILiveServer {
  new: ILiveMonitor[];
  brand: ILiveMonitor[];
  used: ILiveMonitor[];
}

export interface ISocialNumbers {
  budget: number;
  impressions: number;
  views: number;
}

export interface ISocialTotals {
  new: number;
  used: number;
  brand: number;
  total: number;
  youtube: ISocialNumbers;
  facebook: ISocialNumbers;
  instagram: ISocialNumbers;
  ctv: ISocialNumbers;
  inapp: ISocialNumbers;
  websites: ISocialNumbers;
}

export interface ILiveClient {
  all: ISocialTotals;
  brand: ISocialTotals;
  new: ISocialTotals;
  used: ISocialTotals;
  [key: string]: ISocialTotals;
}

export interface IPublisherOverviewItem {
  VTR: number;
  budget: string;
  icon: string;
  impressions: number;
  name: string;
  type: string;
  views: number;
  descriptionBullets?: [string, string][];
  bullets?: [string, string][];
}

export type TPublisherOverview = Record<string, IPublisherOverviewItem[]>;

export type TDate = {
  date: string;
  timezone_type: number;
  timezone: string;
};
export interface ITopAd {
  bullets: [string, string][];
  campaignId: number;
  campaignNumber: string;
  campaignTitle: string | null;
  endDate: TDate;
  finalVideoName: string;
  jobId: number;
  jobNumber: string;
  jobTitle: string | null;
  startDate: TDate;
  title: string;
  thumbnailURL: string;
  type: string;
  videoID: number;
  videoUrl: string;
}

export type TTopAds = Record<string, ITopAd[]>;

export interface IInventoryItem {
  bullets: [string | number, string | number][]
  imageURL: string;
  inventoryWrapperId: number;
  inventoryWrapperName: string;
  numberToPromote: number;
}

export type TInventory = Record<string, IInventoryItem[]>;

export interface IPromotionRecordData {
  inventoryWrapperId: number;
  icon: string;
  packages: {
    packageId: number;
    title: string;
    cost: number;
    icon: string;
    channels: {
      title: string;
      icon: string;
    }[];
    bullets: [string | number, string | number][];
    isSubscribed: boolean;
    pricing: {
      inventorySetId: number;
      price: number;
    }[];
  }[];
  inventorySets: {
    inventorySetId: number;
    title: string;
    icon: string;
    numberToPromote: number;
    cost: number;
    bullets: [string | number, string | number][];
  }[];
}

export interface IPromotionData {
  inventoryData: Record<string, IPromotionRecordData>;
  evergreenVideos: {
    evergreenVideoId: number;
    icon: string;
    video: string;
  }[];
}

export interface ICartTotal {
  uuid: string;
  title: string;
  icon: string;
  cost: number;
  audiences: number;
  vehicles: number;
  subscribed: boolean;
  cartId:number;
}

export interface IDealerInfo {
  logo: string;
  partnerName: string;
  ucid: number;
}

export interface IPublisherCampaign {
  adGroups: Record<string, string>;
  name: string;
}

export interface IPublisherOption {
  campaigns?: Record<string, IPublisherCampaign>;
  channelId: number;
  channelName: string;
  needsCampaignSelection: boolean;
  needsFinalUrl: boolean;
}

export interface IServiceOffer {
  amount: string;
  disclaimer: string;
  headline: string;
  imageUrl: string;
  isGlobal?: number
  name: string;
  offerId?: number;
  notEditable?: boolean;
}

export interface DisplayMessage {
  type: "error" | "info" | "success" | "warning" | undefined;
  message: string;
};