import React, {useEffect} from 'react';
import {Avatar, Button, Card, Checkbox, Col, DatePicker, Row, Select, Input, List, Alert} from 'antd';
import {Icon} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import PieChartCard from '../PieChartCard/PieChartCard';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabCard from '../TabCard/TabCard';
import {
  BrowserRouter,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import {
  inventoryView,
  audiencePacingTabData,
  DynamicVideoView,
  optimizationsCardData,
  CartListView,
} from '../../data';
import useAxios from 'axios-hooks';
import {AppContext} from '../AppLayout/AppLayout';
import { lowerCase } from 'lodash';
import { IPromotionData, TInventory, ICartTotal } from '../../interfaces';
import moment from 'moment';
import { FixedSizeList } from 'react-window';
import { useLocalStorage } from '../../hooks/useLocalStorage';

const { RangePicker } = DatePicker;

function disabledDate(current: moment.Moment | null): boolean {
  // Can not select today or later
  return current ? current > moment().subtract(1, 'days').endOf('day') : false;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={'span'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Dashboard: React.FC = () => {
  const [inventoryFilter, setInventoryFilter] = React.useState<string>();
  const [inventorySearch] = React.useState('');
  const [serviceSearch, setServiceSearch] = React.useState('');
  const [topAdsFilter, setTopAdsFilter] = React.useState<string>();
  const [liveFilter, setLiveFilter] = React.useState<string>();
  const [pubFilter, setPubFilter] = React.useState<string>();
  const [selectedIndex] = React.useState(0);
  const {orders,ucid,checkedPromotions, toggleCheckedPromotion, checkedServices, toggleCheckedService, getUrl, handleCampaignCreatorOpen,handleOrderHistoryOpen, handleCheckOut, submitting, cartTotal, cartTotalList, disclaimer, dateRange, setDateRange,editCartCampaign,removeCartCampaign, serviceWrappersData, loadingServiceWrappers, displayMessage, handleLogout} = React.useContext(AppContext);
  const [{ data: lotBreakdownData, loading: loadingLotBreakdown, error:errorLotBreakdown }] = useAxios(getUrl('getLotBreakdown'));
  const [{ data: inventoryWrappersData, loading: loadingInventoryWrappers, error:errorInventoryWrappers }] = useAxios<TInventory>(getUrl('getInventoryWrappers'));
  const [{ data: topAdsData, loading: loadingTopAds, error:errorTopAds }] = useAxios(getUrl('getTopPerformingCampaigns', '', true));
  const [{ data: publisherOverviewData, loading: loadingPublisherOverview, error: errorPublisherOverview }] = useAxios(getUrl('getPublisherOverview', '', true));
  const [{ data: liveMonitorData, loading: loadingLiveMonitor, error:errorLiveMonitor }] = useAxios(getUrl('getLiveMonitoring', '', true));
  const [{data: salesforceJobList}] = useAxios(getUrl('getJobNumbers')); 
  const [valueOS, setOSValue] = React.useState(0);
  const handleOSChange = (event: React.SyntheticEvent, newValue: number) => {
    setOSValue(newValue);
  };
  let allWrappers = inventoryWrappersData?.all?.map((item)=>{return item.inventoryWrapperId});
  if(allWrappers){
    localStorage.setItem('allWrappers',JSON.stringify([allWrappers,inventoryWrappersData]));
  }

  const topAdsFilters = topAdsData ? Object.keys(topAdsData) : [];
  const topAdsFilterValue = topAdsFilter || topAdsFilters[0];
  const liveMonitorFilters = liveMonitorData ? Object.keys(liveMonitorData) : [];
  const liveMonitorFilterValue = liveFilter || liveMonitorFilters[0];
  const inventoryFilters = inventoryWrappersData ? Object.keys(inventoryWrappersData) : [];
  const inventoryFilterValue = inventoryFilter || inventoryFilters[0];
  const pubData = publisherOverviewData ? audiencePacingTabData(publisherOverviewData) : [];
  const pubFilterValue = pubFilter || pubData[0]?.title;
  const isInventory = selectedIndex === 0;
  const filteredServiceData = serviceWrappersData?.length?(serviceWrappersData?.filter(((i) => lowerCase(i.name).includes(lowerCase(serviceSearch))))):[];
  let orderDisplay = orders?JSON.parse(JSON.stringify(orders)):{};

  
  useEffect(()=>{
    if (`${errorLotBreakdown}`.includes('401') 
        || `${loadingInventoryWrappers}`.includes('401')
        || `${errorTopAds}`.includes('401')
        || `${errorPublisherOverview}`.includes('401')
        || `${errorLiveMonitor}`.includes('401')
      ) { 
        handleLogout('')
    }
  },[errorLotBreakdown,errorInventoryWrappers,errorTopAds,errorPublisherOverview,errorLiveMonitor]);

  return (
    <div className="dashboard-container">
        {displayMessage.length>0 && (
          <Row style={{marginRight: '12px'}}>
            <Alert type={displayMessage[0].type} 
                  message={displayMessage[0].message} 
                  style={{textAlign: 'center', fontSize: 'large', fontWeight: 'bold'}}
            /> 
          </Row> 
        )}
      <Row className="dashboard-row light-back">
        
        <Col md={12} lg={12} xl={6} xxl={6} className="border-bottom">
          <PieChartCard
            list={lotBreakdownData || []}
            bordered={false}
            loading={loadingLotBreakdown}
            className="inventory-pie-chart"
          />
        </Col>
        <Col md={12} lg={12} xl={12} xxl={12} className="border-bottom border-left border-right">
          <h3
            className="your-inventory-title"
            style={isInventory ? {} : {
              width: '100%'
            }}
          >
            Inventory
            {!isInventory && (
              <>
                <Input
                  value={serviceSearch}
                  placeholder="Search services"
                  onChange={e => setServiceSearch(e.target.value)}
                  style={{
                    width: 150,
                    position: 'absolute',
                    right: 96,
                    top: 15,
                  }}
                />
                <Link
                  component={RouterLink}
                  to="/service"
                  style={{
                    position: 'absolute',
                    right: 30,
                    top: 15,
                    color: '#fff',
                  }}
                >
                  <Icon style={{fontSize: 36}}>edit</Icon>
                </Link>
              </>
            )}
          </h3>
          {isInventory ? (
            <Card
              size="small"
              bordered={false}
              loading={loadingInventoryWrappers}
              className="your-inventory-box"
            >
              <div className="your-inventory-filter">
                <div>
                  <span>Filter:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <Select
                    value={inventoryFilterValue}
                    style={{ width: 200 }}
                    onChange={setInventoryFilter}
                  >
                    {inventoryFilters.map(v => (
                      <Select.Option key={v} value={v}>
                        <span style={{textTransform: 'capitalize'}}>{v}</span>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </div>
              <div className="inventory-scroll-box " id="style-1">
                {inventoryView(
                  inventoryWrappersData?.[inventoryFilterValue]?.filter(((i) => lowerCase(i.inventoryWrapperName).includes(lowerCase(inventorySearch)))) ||
                  [],
                  checkedPromotions,
                  toggleCheckedPromotion,
                )}
              </div>
              <div className="promote-btn-wrapper">
                <Button
                  type="primary"
                  shape="round"
                  size="small"
                  onClick={handleCampaignCreatorOpen}
                  disabled={!checkedPromotions.length}
                >
                  Promote Selected
                </Button>
              </div>
            </Card>
          ) : (
            <Card
              size="small"
              bordered={false}
              loading={loadingServiceWrappers}
              className="your-inventory-box"
            >
              <div className="inventory-scroll-box">
                <FixedSizeList height={460} width={611} itemSize={106} itemCount={filteredServiceData.length}>
                  {({ index, style }) => {
                    const item = filteredServiceData[index];
                    return (
                      <List.Item
                        style={{
                          ...style,
                          boxSizing: 'border-box',
                        }}
                        actions={[
                          <Checkbox
                            checked={checkedServices.includes(item.offerId!)}
                          />
                        ]}
                        onClick={() => toggleCheckedService(item.offerId!)}
                      >
                        <List.Item.Meta
                          avatar={<Avatar size={75} icon={<img src={item.imageUrl} alt={item.name} />} />}
                          title={item.name}
                          description={(
                            <div className="car-list-extra">
                              <span
                                style={{
                                  display: 'block',
                                  maxHeight: 40,
                                  textOverflow: 'ellipsis',
                                  width: 437,
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                }}
                                >
                                {item.headline}
                              </span>
                            </div>
                          )}
                        />
                      </List.Item>
                    );
                }}
                </FixedSizeList>
              </div>
              <div className="promote-btn-wrapper">
                <Button
                  type="primary"
                  shape="round"
                  size="small"
                  disabled={!checkedServices.length}
                >
                  Promote Selected
                </Button>
              </div>
            </Card>
          )}
        </Col>
        <Col md={12} lg={12} xl={6} xxl={6} className="border-bottom">
          <Card
            className="your-order-card tab-card"
            size="small"
            bordered={false}
          >
          
           
                 <Box sx={{ width: '100%',height:'100%' }}>
                 <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                   <Tabs value={valueOS} onChange={handleOSChange} aria-label="basic tabs example">
                    <Tab style={{color:'white',fontSize:'20px'}} label={('Cart ('+cartTotalList.length+')')}  {...a11yProps(0)} />
                    <Tab style={{color:'white',fontSize:'20px'}} label="Order History" {...a11yProps(1)} />
                    </Tabs>
                  </Box>
            <TabPanel value={valueOS} index={0}>
            <div className="inventory-scroll-box " id="style-1" style={{height:'345px'}}>
      <CartListView
              className="card-scroll-content"
              data={cartTotalList}
              total={cartTotal}
              disclaimer={disclaimer}
              showSignature={false}
              onRemoveCartItem={removeCartCampaign}
              onEditCartItem={editCartCampaign}
            />
            </div>
            <div className="centered-row custom-bt">
              <Button
                type="primary"
                shape="round"
                size="small"
                disabled={cartTotalList.length === 0}
                loading={submitting}
                onClick={handleCheckOut}
              >
                Check Out
              </Button>
            </div>
            <h5 style={{color:'white',marginTop:'20px'}}>*Items in the cart will be saved for 7 days</h5>
            </TabPanel>
            <TabPanel value={valueOS} index={1}>
            <div className="scrollbar" id="style-1"> 
            {orderDisplay?.data?.reverse().map((item:any)=>{
              if(item.ucid == ucid){
                 return <div key={item?.jobId} style={{marginLeft:'3px',fontSize:'17px'}}><Link to='/' onClick={()=>handleOrderHistoryOpen(true)} key={item?.jobId}>#{item.proposalId} - {item.packageName} - {new Date(item.creationDateTime).toLocaleDateString('en-US',{month: 'numeric', day: 'numeric',year: '2-digit'})}</Link></div>
              }
               })} 
            </div>
           </TabPanel>
           </Box>


          </Card>
        </Col>
        {/* <Col md={24} lg={24} xl={24} xxl={24} className="border-bottom live-monitoring-row">
          <div className="live-monitoring">
            <h3>LIVE Monitoring</h3>
            <RangePicker
              size="small"
              disabledDate={disabledDate}
              allowClear={false}
              format="MM/DD/YYYY"
              value={dateRange as any}
              onChange={setDateRange as any}
            />
          </div>
        </Col> */}
        {/* <Col md={24} lg={24} xl={6} xxl={6} className="auto-height border-top">
          <div className="flex-spaced pad-bottom-15">
            <h3 className="budget-text top-performing-title">
              Budget
            </h3>
            <div className="budget-filter top-ads-filter">
              <span>Filter:&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Select
                value={pubFilterValue}
                style={{ width: 100 }}
                onChange={setPubFilter}
              >
                {pubData.map(v => (
                  <Select.Option key={v.title} value={v.title}>
                    <span style={{textTransform: 'capitalize'}}>{v.title}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <Card
            size="small"
            bordered={false}
            loading={loadingPublisherOverview}
            className="budget-box top-ads-box"
          >
            <div className="budget-scroll-box dynamic-video-scroll-box">
              {pubData.filter(i => i.title === pubFilterValue).map(i => i.content)}
            </div>
          </Card>
        </Col> */}
        {/* <Col md={24} lg={24} xl={12} xxl={12} className="border-top auto-height border-left border-right">
          <div className="optimizations-card">
            <div className="chart-top-text space-between">
              <div className="performance-title">Performance</div>
              <div className="live-monitor-filter">
                <span style={{fontSize: 16}}>Filter:&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Select
                  value={liveMonitorFilterValue}
                  style={{ width: 175 }}
                  onChange={setLiveFilter}
                >
                  {liveMonitorFilters.map(v => (
                    <Select.Option key={v} value={v}>
                      <span style={{textTransform: 'capitalize'}}>{v}</span>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
            <TabCard
              defaultActiveKey="0"
              list={optimizationsCardData(liveMonitorData?.[liveMonitorFilterValue] || [])}
              bordered={false}
              loading={loadingLiveMonitor}
            />
          </div>
        </Col> */}
        {/* <Col md={24} lg={24} xl={6} xxl={6} className="auto-height border-top">
          <div className="flex-spaced pad-bottom-15">
            <h3 className="top-performing-title">Ads</h3>
            <div className="top-ads-filter">
              <span>Filter:&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Select
                value={topAdsFilterValue}
                style={{ width: 100 }}
                onChange={setTopAdsFilter}
              >
                {topAdsFilters.map(v => (
                  <Select.Option key={v} value={v}>
                    <span style={{textTransform: 'capitalize'}}>{v}</span>
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
          <Card
            size="small"
            bordered={false}
            loading={loadingTopAds}
            className="top-ads-box"
          >
            <div className="dynamic-video-scroll-box">
              <DynamicVideoView data={topAdsData?.[topAdsFilterValue] || []} />
            </div>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}

export default Dashboard;