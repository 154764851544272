import React from 'react';
import AppLayout from '../AppLayout/AppLayout';
import {HashRouter as Router} from 'react-router-dom';
import Feedback from 'feeder-react-feedback';
import {createTheme, ThemeProvider} from '@material-ui/core';
import {cyan} from '@material-ui/core/colors';
import {SnackbarProvider} from 'notistack';

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: cyan,
  },
});

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <SnackbarProvider maxSnack={3}>
      <Router>
        <AppLayout />
      </Router>
      <Feedback projectId="6094064af4651a0004ba197e" email={true} />
    </SnackbarProvider>
  </ThemeProvider>
);

export default App;
