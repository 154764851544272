import {ILiveServer, ILiveClient, ISocialTotals, Monitors, ISocialNumbers, ILiveMonitor} from '../interfaces';

export const formatToMoney = (value?: string | number) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
export const parseToNumber = (value?: string) => value!.replace(/\$\s?|(,*)/g, '');
export const formatToCommas = (value?: string | number) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const filterOption = (input: any, option:any) =>
  `${option!.props!.children}`.toLowerCase!().indexOf(input.toLowerCase()) >= 0;

export const generateSocialNumbers = (): ISocialNumbers => ({
  budget: 0,
  impressions: 0,
  views: 0,
});

export const generateSocialTotals = (): ISocialTotals => ({
  new: 0,
  used: 0,
  brand: 0,
  total: 0,
  youtube: generateSocialNumbers(),
  facebook: generateSocialNumbers(),
  instagram: generateSocialNumbers(),
  ctv: generateSocialNumbers(),
  inapp: generateSocialNumbers(),
  websites: generateSocialNumbers(),
});

export const getSocialViewTotals = (data: ILiveServer): ILiveClient => {
  const returnData: ILiveClient = {
    all: generateSocialTotals(),
    brand: generateSocialTotals(),
    new: generateSocialTotals(),
    used: generateSocialTotals(),
  };
  const loopFun = (k: string) => (i: ILiveMonitor) => {
    Object.entries(i.channels).forEach(([key, value]) => {
      // @ts-ignore
      returnData[k][k] += value.views;
      returnData[k].total += value.views;
      // @ts-ignore
      returnData.all[k] += value.views;
      returnData.all.total += value.views;
      switch (Number(key)) {
        case Monitors.YouTube:
          returnData[k].youtube.budget += value.budget;
          returnData[k].youtube.impressions += value.impressions;
          returnData[k].youtube.views += value.views;
          returnData.all.youtube.budget += value.budget;
          returnData.all.youtube.impressions += value.impressions;
          returnData.all.youtube.views += value.views;
          break;
        case Monitors.Facebook:
          returnData[k].facebook.budget += value.budget;
          returnData[k].facebook.impressions += value.impressions;
          returnData[k].facebook.views += value.views;
          returnData.all.facebook.budget += value.budget;
          returnData.all.facebook.impressions += value.impressions;
          returnData.all.facebook.views += value.views;
          break;
        case Monitors.Instagram:
          returnData[k].instagram.budget += value.budget;
          returnData[k].instagram.impressions += value.impressions;
          returnData[k].instagram.views += value.views;
          returnData.all.instagram.budget += value.budget;
          returnData.all.instagram.impressions += value.impressions;
          returnData.all.instagram.views += value.views;
          break;
        case Monitors.CTV:
          returnData[k].ctv.budget += value.budget;
          returnData[k].ctv.impressions += value.impressions;
          returnData[k].ctv.views += value.views;
          returnData.all.ctv.budget += value.budget;
          returnData.all.ctv.impressions += value.impressions;
          returnData.all.ctv.views += value.views;
          break;
        case Monitors["In-App"]:
          returnData[k].inapp.budget += value.budget;
          returnData[k].inapp.impressions += value.impressions;
          returnData[k].inapp.views += value.views;
          returnData.all.inapp.budget += value.budget;
          returnData.all.inapp.impressions += value.impressions;
          returnData.all.inapp.views += value.views;
          break;
        case Monitors["Premium Websites"]:
          returnData[k].websites.budget += value.budget;
          returnData[k].websites.impressions += value.impressions;
          returnData[k].websites.views += value.views;
          returnData.all.websites.budget += value.budget;
          returnData.all.websites.impressions += value.impressions;
          returnData.all.websites.views += value.views;
          break;
        default:
          break;
      }
    });
  };
  data.new.forEach(loopFun('new'));
  data.brand.forEach(loopFun('brand'));
  data.used.forEach(loopFun('used'));
  return returnData;
};

export const generatePoints = (data: ILiveMonitor[]): [Date, number][] => data.map(d => {
  let views = 0;
  Object.entries(d.channels).forEach(c => {
    views += c[1].views;
  });
  return [new Date(`${d.date}T00:00`), views];
});
