import { Button } from 'antd';
import * as React from 'react';
import ReactPlayer from 'react-player';
import {AppContext} from '../AppLayout/AppLayout';
import "../../styles/components/OrderDetails.scss";

export default function OrderDetails(order:any) {
  const {
    handleODBack
  } = React.useContext(AppContext);

  const orderDetails = order.order.data;
  console.log(orderDetails);
  return (
    <>
    <div style={{marginRight:'10%'}}>
       <ReactPlayer
         url={orderDetails.latestVideoUrl}
         controls={true}
         light={orderDetails.evergreenPreviewUrl}
         width="100%"
         height={300}

         />
         </div>
         <div className="details">
          <b>Job Description :</b> {orderDetails.jobDescription}
         </div>
         <div className="details">
          <b>Job Number :</b> {orderDetails.jobNumber}
         </div>
         <div className="details">
          <b>Start Date:</b> {orderDetails.startDate}
          <b style={{marginLeft:'20px'}}>{orderDetails.subscribed?"Subscribed":"End Date"} : </b>{orderDetails.subscribed?<strong>&#10003;</strong>:orderDetails.endDate}
         </div>
          <div className="details">
          <b>Price Label: </b>{orderDetails.priceLabel=="" || orderDetails.priceLabel==null? "NA":orderDetails.priceLabel}
          </div>
          <div className="details">
         <b>Created By : </b>{orderDetails.creatorName}
         </div>
         <div className="details">
         <b>Created Date:</b> {orderDetails.created}
         </div>
         <div className="details">
         <b>Modified Date:</b> {orderDetails.lastModified}
         </div>
         <div className="details">
          <Button type='primary' onClick={handleODBack}>Back</Button>
         </div>
    </>
  );
}
