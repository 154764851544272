import React from 'react';
import {Avatar, Card, Carousel} from 'antd';
import { ChartDonut, ChartLabel, ChartLegend, ChartThemeColor, ChartThemeVariant } from '@patternfly/react-charts';
import { Tooltip } from '@patternfly/react-core';

export interface IPieChartCard {
  list: {
    cardTitle?: string;
    cardTitleIcon?: React.ReactNode;
    className?: string;
    showLegend?: boolean;
    startAngle?: number;
    data: {
      color: string;
      title: string;
      value: number;
      subtitle: string;
      legendLabel?: string;
    }[];
    centerData: {
      numerator: string;
      denominator?: string;
      label?: string;
    };
  }[];
  bordered?: boolean;
  loading?: boolean;
  className?: string;
}

const PieChartCard: React.FC<IPieChartCard> = ({
  list,
  bordered = true,
  loading = false,
  className,
}) => {
  let cx = 'pie-chart-carousel';
  if (className) {
    cx += ` ${className}`;
  }
  if (loading) {
    cx += ' loading';
  }
  const cardProps = {
    size: 'small' as any,
    loading,
    bordered,
  };

  const toolTip = (item:string) => {
    if(item.includes('New Arrivals')){
      return 'New arrivals - 0-31 days';
    }else if(item.includes('Pacing')){
      return 'Pacing 31- 60 days';
    }else if(item.includes('Aging')){
      return 'Aging - above 60 days';
    }else{
      return '';
    }
    
  }

   // Custom legend label compoenent
   const LegendLabel = ({datum, ...rest}:any) => (
    toolTip(datum.name) !== ''?
    <Tooltip content={toolTip(datum.name)} enableFlip>
      <ChartLabel {...rest} />
    </Tooltip>:<ChartLabel {...rest} />
  );

  // Custom legend component
  const getLegend = (legendData:any) => (
    <ChartLegend
      data={legendData}
      labelComponent={<LegendLabel />}
    />
  );
  return (
    <Carousel className={cx}>
      {loading && !list.length && (
        <Card
          {...cardProps}
          className="pie-chart-card"
          title={
            <Card.Meta
              style={{ textAlign: 'center' }}
              title={<span style={{visibility: 'hidden'}}>Loading</span>}
            />
          }
       />
      )}
      {list.map(({cardTitle, cardTitleIcon, className, centerData, data, showLegend, startAngle}, index) => {
        const hasTitle = !!cardTitle || !!cardTitleIcon
        return (
          <Card
            {...cardProps}
            className={`pie-chart-card${className ? ` ${className}` : ''}`}
            title={hasTitle ?
              <Card.Meta
                avatar={cardTitleIcon ?
                  <Avatar className={cardTitleIcon === true ? '' : 'transparent'} icon={cardTitleIcon} />
                  : undefined
                }
                style={{ textAlign: 'center' }}
                title={cardTitle}
              />
              : undefined
            }
            key={index}
          >
            <div className="card-scroll-content">
              <div>
                <ChartDonut
                  constrainToVisibleArea={true}
                  data={data.map(d => ({ ...d, x: d.title, y: d.value}))}
                  height={275}
                  labels={({ datum }) => `${datum.title}: ${datum.subtitle}`}
                  legendComponent={getLegend(data.map(d => ({ name: `${d.title}: ${d.subtitle}` })))}
                  legendPosition="bottom"
                  padding={{
                    bottom: 65,
                    left: 20,
                    right: 20,
                    top: 20
                  }}
                  themeColor={ChartThemeColor.multiOrdered}
                  themeVariant={ChartThemeVariant.dark}
                  subTitle={centerData.label}
                  title={`${centerData.numerator}${centerData.denominator ? `/${centerData.denominator}` : ''}`}
                  width={350}
                />
              </div>
            </div>
          </Card>
        );
      })}
    </Carousel>
  );
}

export default PieChartCard;
